import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { Link, UIKitSettingsProvider } from '@passthrough/uikit';

import { TrueLink } from 'components/link';
import {
  DataValueDisplay,
  DataValueDisplayVariants,
} from 'components/data_value_display';
import { UnreadBadge } from 'components/unread_badge';

import formatDate from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import { DatesContainer } from './dates_container';
import { alertVariants } from './constants';
import { PepClassDisplay } from './pep_class_display';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 3),
  },
  greyText: {
    color: theme.palette.primary.grey,
  },
  updatedAtText: {
    display: 'inline-block',
    marginBottom: theme.spacing(1),
  },
  snippetText: {
    display: 'inline-block',
    marginBottom: theme.spacing(1),
  },
  pepClassContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pepClassPaper: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5),
    fontSize: '12px',
    width: 'fit-content',
  },
  titleBottomSpacing: {
    marginBottom: theme.spacing(1),
  },
  datesAndLinkContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  smallFlex: {
    flex: 1,
  },
  invertedIcon: {
    transform: 'rotate(180deg)',
  },
  upperSpacing: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  unreadBadge: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const RELATED_URL = 'Related URL';
const DATA_LINK_KEYS = ['Locationurl'];

function isValidURL(urlString) {
  try {
    // eslint-disable-next-line no-new
    new URL(urlString);
  } catch (error) {
    return false;
  }
  return true;
}

export function AlertCard({
  publishDate,
  updatedAt,
  listingStartDate,
  listingEndDate,
  title,
  url,
  snippet = '',
  variant,
  pepClassNum,
  extraData,
  className,
  lastReviewedAt,
}) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const isAdverseMedia = variant === alertVariants.ADVERSE_MEDIA;
  const isPep = variant === alertVariants.PEP;

  const buttonText = isAdverseMedia ? 'Read more' : 'Source';

  const hasAlertData = Object.keys(extraData).length > 0;
  const dataEntries = hasAlertData ? Object.entries(extraData) : [];
  const relatedUrl = extraData?.[RELATED_URL];

  const isRead =
    lastReviewedAt && isBefore(new Date(updatedAt), new Date(lastReviewedAt));

  return (
    <Paper variant="outlined" className={clsx(classes.root, className)}>
      <div className={clsx({ [classes.flexContainer]: !isAdverseMedia })}>
        {!isRead ? <UnreadBadge className={classes.unreadBadge} /> : null}
        <Typography
          variant="caption"
          className={clsx(classes.updatedAtText, classes.greyText, {
            [classes.smallFlex]: !isAdverseMedia,
          })}
        >
          Updated at {formatDate(new Date(updatedAt), 'HH:mm dd MMM yyyy')}
        </Typography>

        {!isAdverseMedia && hasAlertData ? (
          <IconButton
            aria-label="show_hide_alert_data"
            size="small"
            onClick={() => {
              setIsExpanded((prevState) => !prevState);
            }}
          >
            <ExpandMoreIcon
              color="primary"
              className={clsx({ [classes.invertedIcon]: isExpanded })}
            />
          </IconButton>
        ) : null}
      </div>

      <div
        className={clsx(classes.titleBottomSpacing, {
          [classes.pepClassContainer]: isPep && Boolean(pepClassNum),
        })}
      >
        <Typography variant="subtitle1">{title}</Typography>
      </div>

      {isAdverseMedia && Boolean(snippet) ? (
        <Typography
          variant="caption"
          className={clsx(classes.snippetText, classes.greyText)}
        >
          {snippet}
        </Typography>
      ) : null}

      {isPep && Boolean(pepClassNum) ? (
        <PepClassDisplay
          pepClassNum={pepClassNum}
          className={classes.pepClassPaper}
        />
      ) : null}

      <div className={classes.datesAndLinkContainer}>
        <DatesContainer
          variant={variant}
          publishDate={publishDate}
          listingStartDate={listingStartDate}
          listingEndDate={listingEndDate}
        />

        <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
          {url ? (
            <Link href={url} inline variant="external">
              {buttonText}
            </Link>
          ) : null}
          {relatedUrl ? (
            <Link href={relatedUrl} inline variant="external">
              Related URL
            </Link>
          ) : null}
        </UIKitSettingsProvider>
      </div>

      <Collapse mountOnEnter unmountOnExit in={isExpanded}>
        <div className={classes.upperSpacing}>
          {dataEntries.map(([key, val]) => {
            let displayVal = val;
            if (DATA_LINK_KEYS.includes(key) && isValidURL(val)) {
              displayVal = <TrueLink href={val}>{val}</TrueLink>;
            }

            return (
              <DataValueDisplay
                key={key}
                label={key}
                value={displayVal}
                variant={DataValueDisplayVariants.HORIZONTAL}
              />
            );
          })}
        </div>
      </Collapse>
    </Paper>
  );
}
