import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Typography, Icons } from '@passthrough/uikit';
import { useMediaQuery } from '@material-ui/core';
import { useFeatureFlags } from 'services/providers/feature_flag';
import * as riskConstants from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'stretch',
    },
  },
  toggleButtonRootOld: {
    textTransform: 'none',
    padding: theme.spacing(1, 5),
  },
  toggleButtonRootNew: {
    textTransform: 'none',
    padding: theme.spacing(1, 3),
  },
  criticalRisk: {
    color: theme.palette.error.text,
    '&.MuiToggleButton-root.Mui-selected': {
      color: theme.palette.error.text,
      backgroundColor: theme.palette.error.background,
    },
    '&:hover': {
      backgroundColor: theme.palette.error.background,
    },
  },
  highRisk: {
    color: theme.palette.error.text,
    '&.MuiToggleButton-root.Mui-selected': {
      color: theme.palette.error.text,
      backgroundColor: theme.palette.error.background,
    },
    '&:hover': {
      backgroundColor: theme.palette.error.background,
    },
  },
  moderateRisk: {
    color: theme.palette.warning.text,
    '&.MuiToggleButton-root.Mui-selected': {
      color: theme.palette.warning.text,
      backgroundColor: theme.palette.warning.background,
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.warning.background,
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
  lowRisk: {
    color: theme.palette.success.text,
    '&.MuiToggleButton-root.Mui-selected': {
      color: theme.palette.success.text,
      backgroundColor: theme.palette.success.background,
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.success.background,
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
  noneRisk: {
    color: theme.palette.primary.grey,
    '&.MuiToggleButton-root.Mui-selected': {
      color: theme.palette.primary.grey,
    },
  },
}));

export function RiskRatingSelectionBanner({
  riskChoice,
  changeRiskChoice,
  pendingUpdate,
}) {
  const classes = useStyles();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { MODERATE_AND_CRITICAL_RISK_RATINGS } = useFeatureFlags();

  return (
    <Paper elevation={0} variant="outlined" className={classes.root}>
      <Typography variant="body" size="small">
        Select a recommended risk rating for the investor
      </Typography>

      <ToggleButtonGroup
        value={riskChoice}
        exclusive
        onChange={(e, v) => {
          if (v !== null) {
            // Don't de-select the choice if click the same one
            changeRiskChoice(v);
          }
        }}
        orientation={mobile ? 'vertical' : 'horizontal'}
      >
        {MODERATE_AND_CRITICAL_RISK_RATINGS && (
          <ToggleButton
            value={riskConstants.CRITICAL_RISK}
            aria-label="critical risk"
            className={clsx(classes.toggleButtonRootNew, classes.criticalRisk)}
            disabled={pendingUpdate}
          >
            <Icons.ErrorOutline sx={{ pr: 1 }} /> Critical
          </ToggleButton>
        )}

        <ToggleButton
          value={riskConstants.HIGH_RISK}
          aria-label="high risk"
          className={clsx(
            {
              [classes.toggleButtonRootOld]:
                !MODERATE_AND_CRITICAL_RISK_RATINGS,
              [classes.toggleButtonRootNew]: MODERATE_AND_CRITICAL_RISK_RATINGS,
            },
            classes.highRisk,
          )}
          disabled={pendingUpdate}
        >
          High {!MODERATE_AND_CRITICAL_RISK_RATINGS && 'risk'}
        </ToggleButton>

        {MODERATE_AND_CRITICAL_RISK_RATINGS && (
          <ToggleButton
            value={riskConstants.MODERATE_RISK}
            aria-label="moderate risk"
            className={clsx(classes.toggleButtonRootNew, classes.moderateRisk)}
            disabled={pendingUpdate}
          >
            Moderate
          </ToggleButton>
        )}

        <ToggleButton
          value={riskConstants.LOW_RISK}
          aria-label="low risk"
          className={clsx(
            {
              [classes.toggleButtonRootOld]:
                !MODERATE_AND_CRITICAL_RISK_RATINGS,
              [classes.toggleButtonRootNew]: MODERATE_AND_CRITICAL_RISK_RATINGS,
            },
            classes.lowRisk,
          )}
          disabled={pendingUpdate}
        >
          Low {!MODERATE_AND_CRITICAL_RISK_RATINGS && 'risk'}
        </ToggleButton>

        <ToggleButton
          value={riskConstants.NO_RATING}
          aria-label="unable to assess"
          className={clsx(
            {
              [classes.toggleButtonRootOld]:
                !MODERATE_AND_CRITICAL_RISK_RATINGS,
              [classes.toggleButtonRootNew]: MODERATE_AND_CRITICAL_RISK_RATINGS,
            },
            classes.noneRisk,
          )}
          disabled={pendingUpdate}
        >
          Unable to assess
        </ToggleButton>

        <ToggleButton
          value={riskConstants.NO_CHOICE}
          aria-label="no choice"
          className={clsx(
            {
              [classes.toggleButtonRootOld]:
                !MODERATE_AND_CRITICAL_RISK_RATINGS,
              [classes.toggleButtonRootNew]: MODERATE_AND_CRITICAL_RISK_RATINGS,
            },
            classes.noneRisk,
          )}
          disabled={pendingUpdate}
        >
          None
        </ToggleButton>
      </ToggleButtonGroup>
    </Paper>
  );
}
