import axios from 'axios';

axios.default.withCredentials = true;

axios.defaults.headers.common['X-CSRFTOKEN'] = window.CSRF_TOKEN;

export function loginWithPassword({ email, password }) {
  return axios.post('/api/login/password/', { email, password });
}

export function loginWithEmail({ email }) {
  return axios.post('/api/login/email/', { email });
}

export function logout() {
  return axios.post('/api/logout/');
}

export function signup({
  firstName,
  lastName,
  hasAcceptedPrivacyAndTerms,
  hasAcceptedPlatformTerms,
}) {
  return axios.post('/api/signup/', {
    firstName,
    lastName,
    hasAcceptedPrivacyAndTerms,
    hasAcceptedPlatformTerms,
  });
}

export function acceptTerms({ hasAcceptedPrivacyAndTerms }) {
  return axios.post('/api/accept-terms/', { hasAcceptedPrivacyAndTerms });
}

export function checkPassword({ password, firstName, lastName }) {
  return axios.post('/api/password/strength/', {
    password,
    firstName,
    lastName,
  });
}

export function getDocsCookie() {
  return axios.get('/api/docs/cookie/');
}

export function systemMessage() {
  return axios.get('/api/system/message/');
}

export function getManageProfiles() {
  return axios.get('/api/profiles/');
}

export function profile({ id }) {
  return axios.get(`/api/profile/${id}/`);
}

export function profileUsers({ profileId }) {
  return axios.get(`/api/profile/${profileId}/user/list/`);
}

export function profileUserCreate({ profileId, name, email }) {
  return axios.post(`/api/profile/${profileId}/user/create/`, { name, email });
}

export function profileUserDelete({ profileId, profileUserId }) {
  return axios.delete(
    `/api/profile/${profileId}/user/${profileUserId}/delete/`,
  );
}

export function saveUserSettings({
  firstName,
  lastName,
  oldPassword,
  password,
  retypePassword,
}) {
  return axios.put('/api/me/update/', {
    firstName,
    lastName,
    oldPassword,
    password,
    retypePassword,
  });
}

export function saveUserDiligenceInstructionDisplaySetting({
  hideDiligenceInstructions,
}) {
  return axios.put('/api/me/update/diligence-instructions-display', {
    hideDiligenceInstructions,
  });
}

export function externalSignup({ slug, name, email }) {
  return axios.post(`/api/external/${slug}/signup/`, { name, email });
}
export function externalSignupGet({ slug }) {
  return axios.get(`/api/external/${slug}/`);
}

export function getSupportPIN() {
  return axios.get('/api/me/support-pin/');
}

/** LP URLs */
export function me() {
  return axios.get('/api/me/');
}

export function userEmail({ userId }) {
  return axios.get(`/api/email/${userId}/`);
}

export function tasks() {
  return axios.get('/api/tasks/');
}

export function lpClosing({ lpClosingId }) {
  return axios.get(`/api/lpclosing/${lpClosingId}/`);
}

export function lpClosingGetBoxes({ lpClosingId }) {
  return axios.get(`/api/lpclosing/${lpClosingId}/boxes/`);
}

export function lpDocAccept({ lpDocumentId }) {
  return axios.post(`/api/lpclosing/${lpDocumentId}/accept/`);
}

export function lpDocAcceptanceGet({ lpDocumentId }) {
  return axios.get(`/api/lpclosing/${lpDocumentId}/accept/get/`);
}

export function lpDocAnswer({ lpClosingId, label, answer, skipQuestion }) {
  return axios.put(`/api/lpclosing/${lpClosingId}/answer/${label}/`, {
    answer,
    skipQuestion,
  });
}

export function createLpComment({
  lpClosingId,
  label,
  lpComment,
  isDiligence,
}) {
  return axios.post(`/api/lpclosing/${lpClosingId}/comment/${label}/`, {
    lpComment,
    isDiligence,
  });
}

export function confirmSection({ lpId, lpClosingId, sectionId }) {
  return axios.patch(
    `/api/lp/${lpId}/lpclosing/${lpClosingId}/section-confirm/`,
    { sectionId },
  );
}
export function lpClosingComplete({ lpClosingId }) {
  return axios.post(`/api/lpclosing/${lpClosingId}/complete/`);
}

export function lpClosingRequestReview({ lpClosingId }) {
  return axios.post(`/api/lpclosing/${lpClosingId}/request-review/`);
}

export function lpClosingGetSignersForTimeline({ lpClosingId }) {
  return axios.get(`/api/lpclosing/${lpClosingId}/get-signers-for-timeline/`);
}

export function lpClosingGetSignersForSecondSignatureTimeline({ lpClosingId }) {
  return axios.get(
    `/api/lpclosing/${lpClosingId}/get-signers-for-second-signature-timeline/`,
  );
}

export function lpDoc({ lpClosingId, lpDocId }) {
  return axios.get(`/api/lpclosing/${lpClosingId}/doc/${lpDocId}/`);
}

export function signaturePage({ lpClosingId, lpDocId }) {
  return axios.get(`/api/lpclosing/${lpClosingId}/doc/${lpDocId}/sign/`);
}

export function secondSignaturePage({ lpClosingId, lpDocId }) {
  return axios.get(`/api/lpclosing/${lpClosingId}/doc/${lpDocId}/sign/second/`);
}

export function uploadOrDropSigned({ lpClosingId, lpDocId, fileId }) {
  return axios.post(`/api/lpclosing/${lpClosingId}/doc/${lpDocId}/upload/`, {
    fileId,
  });
}

export function secondSignatures({ lpClosingId }) {
  return axios.get(`/api/lpclosing/${lpClosingId}/second/signature/list/`);
}

export function templatePdfServeURL({ lpDocId }) {
  return `/api/doc/${lpDocId}/serve/`;
}

export function filledPdfServeURL({ lpDocId }) {
  return `/api/doc/${lpDocId}/serve/filled/`;
}

export function lpUserList({ lpId }) {
  return axios.get(`/api/lp/${lpId}/list/`);
}

export function lpUserDelete({ lpId, lpUserId }) {
  return axios.delete(`/api/lp/${lpId}/lpuser/${lpUserId}/delete/`);
}

/** GP URLs */

export function countersignaturePage({ lpClosingId, lpDocId }) {
  return axios.get(`/api/lpclosing/${lpClosingId}/doc/${lpDocId}/countersign/`);
}

export function bulkCountersignaturePage({ fundId, envelopeId }) {
  return axios.get(
    `/api/fund/${fundId}/envelope/${envelopeId}/countersign/bulk/`,
  );
}

export function countersignTasks({ fundId, closingId }) {
  return axios.get(
    `/api/fund/${fundId}/closing/${closingId}/countersign/tasks/`,
  );
}

/** Law firm URLs */

export function organization({ id }) {
  return axios.get(`/api/organization/${id}/`);
}

export function organizationFunds({ organizationId }) {
  return axios.get(`/api/organization/${organizationId}/fund/`);
}

export function organizationFundListCommitment({
  organizationId,
  batchedFundIds,
}) {
  return axios.get(`/api/organization/${organizationId}/fund/commitment/`, {
    params: { batched_fund_ids: batchedFundIds },
  });
}

export function orgAdmins({ organizationId }) {
  return axios.get(`/api/organization/${organizationId}/admins/`);
}

export function createOrgAdmin({ name, email, organizationId }) {
  return axios.post(`/api/organization/${organizationId}/admins/`, {
    name,
    email,
  });
}

export function deleteOrgAdmin({ organizationId, adminId }) {
  return axios.delete(`/api/organization/${organizationId}/admins/${adminId}/`);
}

export function integrations({ organizationId }) {
  return axios.get(
    `/public/api/internal/organizations/${organizationId}/integrations/`,
  );
}

export function getIntegrationEventTypes({ organizationId }) {
  return axios.get(
    `/public/api/internal/organizations/${organizationId}/integrations/event-types/`,
  );
}

export function createIntegration({
  organizationId,
  name,
  callbackUrl,
  eventTypes,
}) {
  return axios.post(
    `/public/api/internal/organizations/${organizationId}/integrations/`,
    {
      name,
      callbackUrl,
      eventTypes,
    },
  );
}

export function deleteIntegration({ organizationId, integrationId }) {
  return axios.delete(
    `/public/api/internal/organizations/${organizationId}/integrations/${integrationId}/`,
  );
}

export function updateIntegration({
  organizationId,
  integrationId,
  name,
  callbackUrl,
  eventTypes,
}) {
  return axios.put(
    `/public/api/internal/organizations/${organizationId}/integrations/${integrationId}/`,
    {
      name,
      callbackUrl,
      eventTypes,
    },
  );
}

export function fund({ id }) {
  return axios.get(`/api/fund/${id}/`);
}

export function fundFlagsByLpClosing({ lpClosingId }) {
  return axios.get(`/api/fund/lpclosing/${lpClosingId}/flags/`);
}

export function funds() {
  return axios.get('/api/fund/');
}

export function fundSettings({ fundId }) {
  return axios.get(`/api/fund/${fundId}/settings/`);
}

export function updateFundSettings({ fundId, form }) {
  return axios.patch(`/api/fund/${fundId}/settings/`, {
    ...form,
  });
}

export function fundListCommitment({ batchedFundIds }) {
  return axios.get('/api/fund/commitment/', {
    params: { batched_fund_ids: batchedFundIds },
  });
}

export function targetCommitmentUpdate({ fundId, targetCommitment }) {
  return axios.patch(`/api/fund/${fundId}/target-commitment/`, {
    targetCommitment,
  });
}

export function fundExternalAccess({ id, externalAccessPaused }) {
  return axios.patch(`/api/fund/${id}/external-access/`, {
    externalAccessPaused,
  });
}

export function fundGoLive({ fundId, approvedDomains, organizationAdmins }) {
  return axios.patch(`/api/fund/${fundId}/go-live/`, {
    approvedDomains,
    orgAdmins: organizationAdmins,
  });
}

export function offering({ fundId }) {
  return axios.get(`/api/fund/${fundId}/offering/`);
}

export function updateOffering({
  fundId,
  waterfallType,
  managementFeePercentage,
  carryPercentage,
  clawback,
}) {
  return axios.put(`/api/fund/${fundId}/offering/update/`, {
    waterfallType,
    managementFeePercentage,
    carryPercentage,
    clawback,
  });
}

export function subscriptionDoc({ fundId, closingId }) {
  return axios.get(`/api/fund/${fundId}/closing/${closingId}/subscriptiondoc/`);
}

export function clearLpDocSignatures({ fundId, lpDocId }) {
  return axios.patch(
    `/api/fund/${fundId}/closing/lpdocument/${lpDocId}/clearsignatures/`,
    {},
  );
}

export function subscriptionDocs({ fundId }) {
  return axios.get(`/api/fund/${fundId}/subscriptiondoc/list/`);
}

export function subscriptionDocArchive({ fundId, subscriptionDocId }) {
  return axios.post(
    `/api/fund/${fundId}/subscriptiondoc/${subscriptionDocId}/archive/`,
  );
}

export function subscriptionDocUnarchive({ fundId, subscriptionDocId }) {
  return axios.post(
    `/api/fund/${fundId}/subscriptiondoc/${subscriptionDocId}/unarchive/`,
  );
}

export function offeringDocs({ fundId }) {
  return axios.get(`/api/fund/${fundId}/offering/list/`);
}

export function offeringDocDelete({ fundId, offeringDocId }) {
  return axios.delete(`/api/fund/${fundId}/offering/${offeringDocId}/delete/`);
}

export function offeringDocArchive({ fundId, offeringDocId }) {
  return axios.post(`/api/fund/${fundId}/offering/${offeringDocId}/archive/`);
}

export function offeringDocUnarchive({ fundId, offeringDocId }) {
  return axios.post(`/api/fund/${fundId}/offering/${offeringDocId}/unarchive/`);
}

export function offeringDocUpdate({ fundId, offeringDocId, name }) {
  return axios.put(`/api/fund/${fundId}/offering/${offeringDocId}/`, { name });
}

export function closings({ fundId, closingType }) {
  if (closingType)
    return axios.get(`/api/fund/${fundId}/closing/list/`, {
      params: { closing_type: closingType },
    });
  return axios.get(`/api/fund/${fundId}/closing/list/`);
}

export function closing({ fundId, closingId }) {
  return axios.get(`/api/fund/${fundId}/closing/${closingId}/`);
}

export function existingSignUpLinks({ fundId, closingId }) {
  return axios.get(`/api/fund/${fundId}/closing/${closingId}/links/list/`);
}

export function investor({ fundId, investorId }) {
  return axios.get(`/api/fund/${fundId}/lp/${investorId}/`);
}

export function investorAddUser({ fundId, lpId, name, email, notify }) {
  return axios.post(`/api/fund/${fundId}/investors/${lpId}/user/add/`, {
    name,
    email,
    notify,
  });
}

export function investorSaveName({ fundId, lpId, name }) {
  return axios.put(`/api/fund/${fundId}/investors/${lpId}/name/save/`, {
    name,
  });
}

export function investorDeleteUser({ fundId, lpId, lpUserId }) {
  return axios.delete(
    `/api/fund/${fundId}/investors/${lpId}/user/${lpUserId}/delete/`,
  );
}

// TODO: Delete unused function
export function investorSanctionsDismiss({
  fundId,
  lpDiligenceId,
  note,
  matches,
}) {
  return axios.put(
    `/api/fund/${fundId}/lpdiligence/${lpDiligenceId}/sanctions/dismiss/`,
    { matches, note },
  );
}

// TODO: Delete unused function
export function investorSanctionsRestore({
  fundId,
  lpDiligenceId,
  name,
  restoredUids,
}) {
  return axios.put(
    // cspell:words uids
    `/api/fund/${fundId}/lpdiligence/${lpDiligenceId}/sanctions/restore/`,
    { name, restoredUids },
  );
}

// TODO: Delete unused function
export function questions({ fundId, fundClosingId }) {
  return axios.get(
    `/api/fund/${fundId}/closing/${fundClosingId}/question/list/`,
  );
}

// TODO: Delete unused function
export function sideletters({ fundId }) {
  return axios.get(`/api/fund/${fundId}/sideletter/list/`);
}

export function createUploadedDocument({
  fundId,
  name,
  fileId,
  lpClosingIds,
  sendImmediately,
  pdfBoxes,
  message,
  dataModelType,
  type,
  signatureRequired,
  ...terms
}) {
  return axios.post(`/api/fund/${fundId}/uploaded-document/create/`, {
    fundId,
    name,
    fileId,
    lpClosingIds,
    sendImmediately,
    pdfBoxes,
    message,
    dataModelType,
    type,
    signatureRequired,
    ...terms,
  });
}

// TODO: Delete unused function
export function deleteSideletter({ fundId, sideletterId }) {
  return axios.delete(`/api/fund/${fundId}/sideletter/${sideletterId}/delete/`);
}

export function customGroups({ fundId }) {
  return axios.get(`/api/fund/${fundId}/custom_group/list/`);
}

export function performCustomAction({ fundId, lpClosingId, actionId }) {
  return axios.patch(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/action/${actionId}/`,
  );
}

export function members({ fundId }) {
  return axios.get(`/api/fund/${fundId}/collaborator/list/`);
}

export function createMember({
  name,
  email,
  level,
  fundId,
  groupId,
  transactionEmailsEnabled,
  digestEmailsEnabled,
}) {
  return axios.post(`/api/fund/${fundId}/collaborator/create/`, {
    name,
    email,
    level,
    groupId,
    transactionEmailsEnabled,
    digestEmailsEnabled,
  });
}

export function updateMember({
  memberId,
  level,
  groupId,
  fundId,
  transactionEmailsEnabled,
  digestEmailsEnabled,
}) {
  return axios.patch(`/api/fund/${fundId}/collaborator/${memberId}/update/`, {
    level,
    groupId,
    transactionEmailsEnabled,
    digestEmailsEnabled,
  });
}

export function deleteMember({ memberId, fundId }) {
  return axios.delete(`/api/fund/${fundId}/collaborator/${memberId}/delete/`);
}

export function getMemberToExpire({ memberId, fundId }) {
  return axios.get(`/api/fund/${fundId}/collaborator/${memberId}/expire/`);
}

export function expireMember({ memberId, fundId }) {
  return axios.patch(`/api/fund/${fundId}/collaborator/${memberId}/expire/`);
}

export function createClosing({
  name,
  closingDate,
  fundId,
  subscriptionDocumentId,
  defaultCountersignerId,
  defaultCountersigner2Id,
  defaultCountersigner3Id,
  diligenceEnabled,
  diligenceJurisdiction,
  defaultDiligenceApproverId,
  closingTypes,
  diligenceClosingId,
}) {
  return axios.post(`/api/fund/${fundId}/closing/create/`, {
    name,
    closingDate,
    subscriptionDocumentId,
    defaultCountersignerId,
    defaultCountersigner2Id,
    defaultCountersigner3Id,
    diligenceEnabled,
    diligenceJurisdiction,
    defaultDiligenceApproverId,
    closingTypes,
    diligenceClosingId,
  });
}

export function deleteClosing({ fundId, closingId }) {
  return axios.delete(`/api/fund/${fundId}/closing/${closingId}/delete/`);
}

// TODO: Remove when removing NEW_CLOSING_SETTINGS
export function editClosing({
  name,
  closingDate,
  downloadable,
  disableOfflineSigning,
  countersignerId,
  countersigner2Id,
  countersigner3Id,
  diligenceApproverId,
  fundId,
  closingId,
}) {
  return axios.patch(`/api/fund/${fundId}/closing/${closingId}/edit/`, {
    name,
    closingDate,
    downloadable,
    disableOfflineSigning,
    countersignerId,
    countersigner2Id,
    countersigner3Id,
    diligenceApproverId,
  });
}

export function updateClosingSettings({ fundId, closingId, form }) {
  return axios.patch(`/api/fund/${fundId}/closing/${closingId}/settings/`, {
    ...form,
  });
}

export function changeDiligenceApprover({
  fundId,
  closingId,
  diligenceApproverId,
  lpClosingIds,
}) {
  return axios.patch(
    `/api/fund/${fundId}/closing/${closingId}/diligence-approver-edit/`,
    {
      diligenceApproverId,
      lpClosingIds,
    },
  );
}

export function FundLPClosings({ fundId }) {
  return axios.get(`/api/fund/${fundId}/lpdoc/list/`);
}

export function LPClosings({ fundId, closingId }) {
  return axios.get(`/api/fund/${fundId}/closing/${closingId}/lpdoc/list/`);
}

export function getFundTagData({ fundId }) {
  return axios.get(`/api/fund/${fundId}/tag-data/`);
}

export function createLpClosings({ fundId, closingId, investors }) {
  return axios.post(`/api/fund/${fundId}/closing/${closingId}/lpdoc/create/`, {
    investors,
  });
}

export function getCreateLPClosingsTaskResult({ fundId, closingId, taskId }) {
  return axios.get(
    `/api/fund/${fundId}/closing/${closingId}/lpdoc/create/?task_id=${taskId}`,
  );
}

export function detachDocument({
  fundId,
  closingId,
  lpClosingIds,
  sideletterId,
  offeringDocumentId,
}) {
  return axios.post(`/api/fund/${fundId}/closing/${closingId}/lpdoc/detach/`, {
    lpClosingIds,
    sideletterId,
    offeringDocumentId,
  });
}

export function moveClosingDocs({
  fundId,
  closingId,
  lpClosingIds,
  newClosingId,
  diligenceApproverId,
}) {
  return axios.post(`/api/fund/${fundId}/closing/${closingId}/lpdoc/move/`, {
    lpClosingIds,
    newClosingId,
    diligenceApproverId,
  });
}

export function tagInvestors({
  fundId,
  closingId,
  lpClosingIds,
  tagIdsToApply,
  tagIdsToRetain,
}) {
  return axios.post(`/api/fund/${fundId}/closing/${closingId}/tag-investors/`, {
    lpClosingIds,
    tagIdsToApply,
    tagIdsToRetain,
  });
}

export function createFundTag({ fundId, tagName, tagGroupName }) {
  return axios.post(`/api/fund/${fundId}/tag/create/`, {
    tagName,
    tagGroupName,
  });
}

export function editFundTag({ fundId, tagId, tagName, tagGroupName }) {
  return axios.put(`/api/fund/${fundId}/tag/${tagId}/edit/`, {
    tagName,
    tagGroupName,
  });
}

export function editFundTagGroup({ fundId, tagGroupId, newGroupName }) {
  return axios.patch(`/api/fund/${fundId}/tag/group/${tagGroupId}/edit/`, {
    newGroupName,
  });
}

export function deleteTag({ fundId, tagId }) {
  return axios.delete(`/api/fund/${fundId}/tag/${tagId}/delete/`);
}

export function sendClosingDocs({
  fundId,
  closingId,
  lpClosingIds,
  message,
  countersignerId,
  countersigner2Id,
  countersigner3Id,
  diligenceApproverId,
}) {
  return axios.post(`/api/fund/${fundId}/closing/${closingId}/lpdoc/send/`, {
    lpClosingIds,
    message,
    countersignerId,
    countersigner2Id,
    countersigner3Id,
    diligenceApproverId,
  });
}

export function sendReminderClosingDocs({
  fundId,
  closingId,
  lpClosingIds,
  message,
}) {
  return axios.post(`/api/fund/${fundId}/closing/${closingId}/lpdoc/remind/`, {
    lpClosingIds,
    message,
  });
}

export function resendClosingEmail({
  fundId,
  closingId,
  lpClosingIds,
  message,
}) {
  return axios.post(
    `/api/fund/${fundId}/closing/${closingId}/lpdoc/resend-closing-email/`,
    { lpClosingIds, message },
  );
}

export function exportFundClosings({ fundId, closingIds }) {
  return axios.post(`/api/fund/${fundId}/export/`, { closingIds });
}

export function getFundClosingsExport({ fundId, exportId }) {
  return axios.get(`/api/fund/${fundId}/export/${exportId}/status/`);
}

export function exportLpClosings({ fundId, closingId, lpClosingIds }) {
  return axios.post(`/api/fund/${fundId}/closing/${closingId}/lpdoc/export/`, {
    lpClosingIds,
  });
}

export function exportLpAnswers({ fundId, closingId }) {
  return axios.post(`/api/fund/${fundId}/closing/${closingId}/answers/export/`);
}

export function exportDiligenceData({ fundId, closingId, lpClosingIds }) {
  return axios.post(
    `/api/fund/${fundId}/closing/${closingId}/diligence/export/`,
    { lpClosingIds },
  );
}

export function getLpClosingsExport({ fundId, closingId, exportId }) {
  return axios.get(
    `/api/fund/${fundId}/closing/${closingId}/lpdoc/export/${exportId}/status/`,
  );
}

export function getZipDocTypeCount({ fundId, closingId, lpClosingIds }) {
  return axios.post(
    `/api/fund/${fundId}/closing/${closingId}/lpdoc/zip/doc-type-count/`,
    { lpClosingIds },
  );
}

export function zipLpClosings({
  fundId,
  closingId,
  lpClosingIds,
  allowedDocTypes,
  isFlattened,
}) {
  return axios.post(`/api/fund/${fundId}/closing/${closingId}/lpdoc/zip/`, {
    lpClosingIds,
    allowedDocTypes,
    isFlattened,
  });
}

export function getZip({ fundId, closingId, zipId }) {
  return axios.get(
    `/api/fund/${fundId}/closing/${closingId}/lpdoc/zip/${zipId}/`,
  );
}

export function approveClosingDocs({ fundId, closingId, lpClosingIds }) {
  return axios.post(`/api/fund/${fundId}/closing/${closingId}/lpdoc/approve/`, {
    lpClosingIds,
  });
}

export function sendToCountersignerClosingDocs({
  fundId,
  closingId,
  lpClosingIds,
  countersignerId,
  countersigner2Id,
  countersigner3Id,
}) {
  return axios.post(
    `/api/fund/${fundId}/closing/${closingId}/lpdoc/send-to-countersigner/`,
    {
      lpClosingIds,
      countersignerId,
      countersigner2Id,
      countersigner3Id,
    },
  );
}

export function finalizeClosingDocs({ fundId, closingId, lpClosingIds }) {
  return axios.post(
    `/api/fund/${fundId}/closing/${closingId}/lpdoc/finalize/`,
    { lpClosingIds },
  );
}

export function deleteClosingDocs({ fundId, closingId, lpClosingIds }) {
  return axios.post(`/api/fund/${fundId}/closing/${closingId}/lpdoc/delete/`, {
    lpClosingIds,
  });
}

export function unapproveClosingDocs({ fundId, closingId, lpClosingIds }) {
  return axios.post(
    `/api/fund/${fundId}/closing/${closingId}/lpdoc/unapprove/`,
    { lpClosingIds },
  );
}

export function viewLpClosing({ fundId, closingId, lpClosingId }) {
  return axios.get(
    `/api/fund/${fundId}/closing/${closingId}/lp/${lpClosingId}/`,
  );
}

export function saveNoteLpClosing({
  fundId,
  closingId,
  lpClosingId,
  label,
  note,
}) {
  return axios.put(
    `/api/fund/${fundId}/closing/${closingId}/lp/${lpClosingId}/note/`,
    { label, note },
  );
}

export function deleteNoteLpClosing({ fundId, closingId, lpClosingId, label }) {
  return axios.put(
    `/api/fund/${fundId}/closing/${closingId}/lp/${lpClosingId}/note/delete/`,
    { label },
  );
}

export function resolveThreadLpClosing({
  fundId,
  closingId,
  lpClosingId,
  label,
}) {
  return axios.put(
    `/api/fund/${fundId}/closing/${closingId}/lp/${lpClosingId}/thread/resolve/`,
    { label },
  );
}

export function reopenThreadLpClosing({
  fundId,
  closingId,
  lpClosingId,
  label,
}) {
  return axios.put(
    `/api/fund/${fundId}/closing/${closingId}/lp/${lpClosingId}/thread/reopen/`,
    { label },
  );
}

export function rejectLpClosing({
  fundId,
  closingId,
  lpClosingId,
  message,
  sendEmail,
}) {
  return axios.post(
    `/api/fund/${fundId}/closing/${closingId}/lp/${lpClosingId}/reject/`,
    { message, sendEmail },
  );
}

export function undoLpClosingChanges({
  fundId,
  closingId,
  lpClosingId,
  message,
  notify,
}) {
  return axios.post(
    `/api/fund/${fundId}/closing/${closingId}/lp/${lpClosingId}/undo-changes/`,
    { message, notify },
  );
}

export function lpClosingEvents({ fundId, lpClosingId }) {
  return axios.get(`/api/fund/${fundId}/lp_closing/${lpClosingId}/event/list/`);
}

export function saveQuestionnaireInternalNote({
  fundId,
  closingId,
  lpClosingId,
  label,
  internalNote,
}) {
  return axios.post(
    `/api/fund/${fundId}/closing/${closingId}/lp_closing/${lpClosingId}/internal-note/`,
    { label, internalNote },
  );
}

export function saveOverallInvestorNote({
  fundId,
  closingId,
  lpClosingIds,
  note,
}) {
  return axios.post(`/api/fund/${fundId}/closing/${closingId}/overall-note/`, {
    lpClosingIds,
    note,
  });
}

export function lpClosingApproveQuestionnaire({ fundId, lpClosingId }) {
  return axios.patch(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/approve-questionnaire/`,
  );
}

export function lpClosingUnapproveQuestionnaire({ fundId, lpClosingId }) {
  return axios.patch(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/unapprove-questionnaire/`,
  );
}

export function lpClosingDiligenceV2({ fundId, lpClosingId }) {
  return axios.get(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence/v2/`,
  );
}

export function lpDiligenceAreSearchesReady({ fundId, lpClosingId }) {
  return axios.get(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence_searches_ready/`,
  );
}

export function lpClosingDiligenceQuestionValidation({
  lpClosingId,
  questionsIdsToValidate,
}) {
  return axios.post(
    `/api/fund/lp_closing/${lpClosingId}/diligence/questions/validate_missing_data/`,
    { questionsIdsToValidate },
  );
}

export function adminSideDiligenceDataValidation({
  fundId,
  closingId,
  lpClosingId,
  questionToValidate,
  newAnswer,
}) {
  return axios.post(
    `/api/fund/${fundId}/closing/${closingId}/lp_closing/${lpClosingId}/admin_diligence/validate_data/`,
    { questionToValidate, newAnswer },
  );
}

export function getDiligenceNodeMatchData({
  fundId,
  lpClosingId,
  diligenceQuestionId,
}) {
  return axios.get(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence_node/${diligenceQuestionId}/matches/`,
  );
}

export function getDiligenceNodeEvents({
  fundId,
  lpClosingId,
  diligenceQuestionId,
}) {
  return axios.get(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence_node/${diligenceQuestionId}/events/`,
  );
}

export function updateDiligenceMatchStatus({
  fundId,
  lpClosingId,
  questionId,
  matchId,
  matchStatus,
  explanations,
}) {
  return axios.patch(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence_node/${questionId}/match/${matchId}/status_update/`,
    { matchStatus, explanations },
  );
}

export function markMatchAsReviewed({
  fundId,
  lpClosingId,
  questionId,
  matchId,
}) {
  return axios.patch(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence_node/${questionId}/match/${matchId}/mark_reviewed/`,
  );
}

export function updateRiskRecommendation({
  fundId,
  lpClosingId,
  riskRecommendation,
}) {
  return axios.patch(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/risk_recommendation_update/`,
    { riskRecommendation },
  );
}

export function approveDiligenceNode({
  fundId,
  lpClosingId,
  questionId,
  note,
}) {
  return axios.patch(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence_node/${questionId}/approve/`,
    { note },
  );
}

export function saveDiligenceInternalNote({
  fundId,
  lpClosingId,
  questionId,
  note,
}) {
  return axios.post(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence_node/${questionId}/internal_note/`,
    { note },
  );
}

export function diligenceNodeRequestChange({
  fundId,
  lpClosingId,
  questionId,
  note,
}) {
  return axios.patch(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence_node/${questionId}/request_change/`,
    { note },
  );
}

export function diligenceNodeDeleteRequestChange({
  fundId,
  lpClosingId,
  questionId,
}) {
  return axios.delete(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence_node/${questionId}/note/delete/`,
  );
}

export function diligenceNodeResolveChangeRequest({
  fundId,
  lpClosingId,
  questionId,
}) {
  return axios.patch(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence_node/${questionId}/resolve_change/`,
  );
}

export function diligenceNodeReopenThread({ fundId, lpClosingId, questionId }) {
  return axios.patch(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence_node/${questionId}/thread/reopen/`,
  );
}

export function unapproveDiligenceNode({ fundId, lpClosingId, questionId }) {
  return axios.patch(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence_node/${questionId}/unapprove/`,
    {},
  );
}

export function generateDiligenceNodeAISummary({
  fundId,
  lpClosingId,
  questionId,
}) {
  return axios.post(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence_node/${questionId}/ai-summary/generate/`,
  );
}

export function diligenceNodeAISummary({
  fundId,
  lpClosingId,
  questionId,
  aiSummaryId,
}) {
  return axios.get(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence_node/${questionId}/ai-summary/${aiSummaryId}/`,
  );
}

export function gpEditNodeDiligence({ fundId, lpClosingId, label, answer }) {
  return axios.put(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence/${label}/edit-node/`,
    {
      answer,
    },
  );
}

export function gpManualNodeSearch({ fundId, lpClosingId, label }) {
  return axios.put(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence/${label}/manual-search/`,
  );
}

export function gpAddExtraDiligenceFiles({
  fundId,
  lpClosingId,
  label,
  updatedFileIds,
}) {
  return axios.put(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence/${label}/add-extra-files/`,
    {
      updatedFileIds,
    },
  );
}

export function lpDocFinalPdfUri({ fundId, closingId, lpDocId }) {
  return `/api/fund/${fundId}/closing/${closingId}/lpdoc/${lpDocId}/pdf/`;
}

export function filledPdfServeURLforGP({ fundId, closingId, lpDocId }) {
  return `/api/fund/${fundId}/closing/${closingId}/lpdoc/${lpDocId}/serve/filled/gp/`;
}

export function getReviewPDFData({ fundId, closingId, lpDocId }) {
  return axios.get(
    `/api/fund/${fundId}/closing/${closingId}/lpdoc/${lpDocId}/pdf_review/`,
  );
}

export function lpDocSigners({ fundId, closingId, lpDocId }) {
  return axios.get(
    `/api/fund/${fundId}/closing/${closingId}/lpdoc/${lpDocId}/signers/`,
  );
}

export function lpDocUpload({
  fundId,
  closingId,
  lpDocId,
  countersigned,
  fileId,
  commitment,
  acceptedCommitment,
}) {
  return axios.post(
    `/api/fund/${fundId}/closing/${closingId}/lpdoc/${lpDocId}/upload/`,
    {
      countersigned,
      fileId,
      commitment,
      acceptedCommitment,
    },
  );
}

export function subscriptionDocumentTestFill({ fundId, subDocId }) {
  return `/api/fund/${fundId}/subscription_document/${subDocId}/token/`;
}

export function getSubscriptionDocumentLogic({ fundId, subDocId }) {
  return `/api/fund/${fundId}/subscription_document/${subDocId}/logic/`;
}

export function getSubscriptionDocumentList({ fundId }) {
  return axios.get(`/api/fund/${fundId}/subscription_document/`);
}

export function fileUploadNameCheck({ fundId, fileId, lpName, legalName }) {
  return axios.post(`/api/fund/${fundId}/file/${fileId}/name-check/`, {
    lpName,
    legalName,
  });
}

export function filePageDimensionsCheck({ fundId, fileId }) {
  return axios.get(`/api/fund/${fundId}/file/${fileId}/page-dimensions-check/`);
}

/** Staff URLs */

export function setSelectedStaffOrg(orgId) {
  return axios.post(`/staff/api/set-selected-org/${orgId}/`);
}

export function uploadLogo({ file, name }) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', name);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axios.post('/staff/api/upload_logo/', formData, config);
}

export function compareSubdocs({ baseId, targetId }) {
  return axios.post(
    `/staff/api/subscription_document/compare/${baseId}/${targetId}/`,
  );
}

export function getSubdocsToCompare() {
  return axios.get('/staff/api/subscription_document/compare/');
}

export function getGIDs(filters, page) {
  return axios.post(
    '/staff/api/gids/',
    {
      funds: filters.funds.map((f) => f.id),
      tags: filters.tags,
      types: filters.types,
      noTag: filters.noTag,
      search: filters.search,
    },
    { params: { page } },
  );
}

export function addGidToTag(gid, tag) {
  return axios.post(`/staff/api/gids/${gid}/`, { tag });
}

export function removeGidFromTag(gid, tag) {
  return axios.delete(`/staff/api/gids/${gid}/`, { data: { tag } });
}

export function onboarding({ fundId }) {
  return axios.get(`/api/fund/${fundId}/onboarding/`);
}

export function onboardingSave({ fundId, data }) {
  return axios.put(`/api/fund/${fundId}/onboarding/save/`, { data });
}

export function getQuestionnaireDraft({ fundId, questionnaireId }) {
  return axios.get(
    `/api/fund/${fundId}/questionnaires/${questionnaireId}/draft/`,
  );
}

export function missingLogicQuestions({ fundId, questionnaireId }) {
  return axios.get(
    `/api/fund/${fundId}/questionnaires/${questionnaireId}/missing-logic/`,
  );
}

export function checkQuestionnaireFileChanged({ fundId, questionnaireId }) {
  return axios.get(
    `/api/fund/${fundId}/questionnaires/${questionnaireId}/check-file-changed/`,
  );
}

export function deleteQuestionnaireDocument({
  fundId,
  questionnaireId,
  documentId,
}) {
  return axios.delete(
    `/api/fund/${fundId}/questionnaires/${questionnaireId}/document/${documentId}/`,
  );
}

export function updateOrCreateQuestionnaireDocument({
  fundId,
  questionnaireId,
  documentId,
  data,
}) {
  return axios.post(
    `/api/fund/${fundId}/questionnaires/${questionnaireId}/document/`,
    {
      ...data,
      id: documentId,
    },
  );
}

export function getQuestionnaireDocumentChanges({ fundId, questionnaireId }) {
  return axios.get(
    `/api/fund/${fundId}/questionnaires/${questionnaireId}/document/`,
  );
}

export function getBackendWarnings({ fundId, questionnaireId }) {
  return axios.get(
    `/api/fund/${fundId}/questionnaires/${questionnaireId}/warnings/`,
  );
}

export function getQuestionSuggestions({
  fundId,
  questionnaireId,
  question,
  answerType,
  reuseCount,
}) {
  return axios.post(
    `/api/fund/${fundId}/questionnaires/${questionnaireId}/suggestions/`,
    { question, answerType, reuseCount },
  );
}

export function getSubdocHealth({ fundId }) {
  return axios.get(`/staff/api/fund/${fundId}/subscription_document/health/`);
}

export function saveQuestionnaireDraft({
  fundId,
  questionnaireId,
  draftData,
  draftTime,
  investorWord,
}) {
  return axios.put(
    `/api/fund/${fundId}/questionnaires/${questionnaireId}/draft/`,
    { draftData, draftTime, investorWord },
  );
}

export function updateQuestionnaireReviewed({
  fundId,
  questionnaireId,
  reviewedQids,
}) {
  return axios.patch(
    `/api/fund/${fundId}/questionnaires/${questionnaireId}/update_reviewed_questions/`,
    { reviewed_qids: reviewedQids },
  );
}

export function transitionQuestionnaireState({
  fundId,
  questionnaireId,
  state,
  totalSeconds,
  reviewerId,
}) {
  return axios.patch(
    `/api/fund/${fundId}/questionnaires/${questionnaireId}/update_state/`,
    { state, totalSeconds, reviewerId },
  );
}

export function saveTotalSecondsToOnboardingLog({
  fundId,
  questionnaireId,
  totalSeconds,
}) {
  return axios.put(
    `/api/fund/${fundId}/questionnaires/${questionnaireId}/seconds/save/`,
    { totalSeconds },
  );
}

export function createQuestionNote({
  fundId,
  questionnaireId,
  qids,
  note,
  originalNoteId,
}) {
  return axios.post(
    `/api/fund/${fundId}/questionnaire/${questionnaireId}/question/note/create/`,
    {
      qids,
      note: note.note,
      flag: note.flag,
      severity: note.severity,
      originalNoteId,
    },
  );
}

export function updateQuestionNote({
  fundId,
  questionnaireId,
  questionNoteId,
  note,
}) {
  return axios.put(
    `/api/fund/${fundId}/questionnaire/${questionnaireId}/question/note/${questionNoteId}/update/`,
    {
      note: note.note,
      flag: note.flag,
      severity: note.severity,
      resolved: note.resolved,
    },
  );
}

export function moveQuestionNotes({
  fundId,
  questionnaireId,
  fromQuestionId,
  toQuestionId,
}) {
  return axios.patch(
    `/api/fund/${fundId}/questionnaire/${questionnaireId}/move-notes/`,
    { fromQuestionId, toQuestionId },
  );
}

export function deleteQuestionNote({
  fundId,
  questionnaireId,
  questionNoteId,
}) {
  return axios.delete(
    `/api/fund/${fundId}/questionnaire/${questionnaireId}/question/note/${questionNoteId}/delete/`,
  );
}

export function setupLpClosingProfile({
  lpClosingId,
  profileId,
  collaborators,
  restarting,
  shouldBecomeOwner = true,
}) {
  return axios.put(`/api/lpclosing/${lpClosingId}/profile_setup/`, {
    profileId,
    collaborators,
    restarting,
    shouldBecomeOwner,
  });
}

export function getSetupTaskStatus({ lpClosingId, taskId }) {
  return axios.get(
    `/api/lpclosing/${lpClosingId}/profile_setup/task/${taskId}/`,
  );
}

export function validateCollaboratorEmail({ name, email, accessLevel }) {
  return axios.post('/api/profile/collaborators/validate/', {
    name,
    email,
    accessLevel,
  });
}

export function getLpClosingProfileSetupUsers({
  lpClosingId,
  setupProfileId,
  shouldBecomeOwner = true,
}) {
  return axios.get(`/api/lpclosing/${lpClosingId}/profile/collaborator/`, {
    params: {
      profile_id: setupProfileId,
      should_become_owner: shouldBecomeOwner,
    },
  });
}

export function getLpClosingCollaborators({ lpClosingId }) {
  return axios.get(`/api/lpclosing/${lpClosingId}/collaborator/`);
}

export function createLpClosingCollaborator({
  lpClosingId,
  name,
  email,
  accessLevel,
  message,
}) {
  return axios.post(`/api/lpclosing/${lpClosingId}/collaborator/`, {
    name,
    email,
    accessLevel,
    message,
  });
}

export function getProfileInvestments(profileId, lpClosingId) {
  return axios.get(
    `/api/lpclosing/${lpClosingId}/profile/${profileId}/investments/`,
  );
}

export function deleteLpClosingCollaborator({ lpClosingId, userId }) {
  return axios.delete(`/api/lpclosing/${lpClosingId}/collaborator/${userId}/`);
}

export function lpClosingCollaboratorSendReminder({
  lpClosingId,
  userId,
  message,
}) {
  return axios.post(
    `/api/lpclosing/${lpClosingId}/collaborator/${userId}/send-reminder/`,
    { message },
  );
}

export function updateLpClosingCollaboratorAccessLevel({
  lpClosingId,
  profileId,
  userId,
  accessLevel,
}) {
  return axios.put(
    `/api/lpclosing/${lpClosingId}/profile/${profileId}/collaborator/${userId}/update-access/`,
    { accessLevel },
  );
}

export function reviewExperience({
  lpId,
  lpClosingId,
  reviewType,
  reviewText,
  provideRecs,
}) {
  return axios.post(
    `/api/lp/${lpId}/lpclosing/${lpClosingId}/experience_review/`,
    {
      reviewType,
      reviewText,
      provideRecs,
    },
  );
}

export function publishQuestionnaire({
  fundId,
  questionnaireId,
  draftTime,
  questionsToAddTags,
}) {
  return axios.post(
    `/api/fund/${fundId}/questionnaires/${questionnaireId}/publish/`,
    { draftTime, questionsToAddTags },
  );
}

export function revertQuestionnaire({ fundId, questionnaireId, draftTime }) {
  return axios.post(
    `/api/fund/${fundId}/questionnaires/${questionnaireId}/revert/`,
    { draftTime },
  );
}

export function validateQuestionnaire({ fundId, questionnaireId, diff }) {
  return axios.post(
    `/api/fund/${fundId}/questionnaires/${questionnaireId}/validate/`,
    { diff },
  );
}

export function uploadNewDraftFile({ fundId, questionnaireId, fileId }) {
  return axios.patch(
    `/api/fund/${fundId}/questionnaires/${questionnaireId}/draft_file_upload/`,
    { fileId },
  );
}

export function dashboardGetFunds({ organizationId }) {
  return axios.get(`/api/organization/${organizationId}/dashboard/funds/`);
}

export function dashboardGetCommitment({ organizationId, batchedFundIds }) {
  return axios.get(
    `/api/organization/${organizationId}/dashboard/commitment/`,
    { params: { batched_fund_ids: batchedFundIds } },
  );
}

export function questionTags({ search }) {
  return axios.get('/api/staff/question_tags/', { params: { search } });
}

export function addQuestionTag({
  tag,
  tagDisplay,
  tagDescription,
  platonicQuestionText,
  questionGids,
}) {
  return axios.post('/api/staff/question_tags/add/', {
    tag,
    tagDisplay,
    tagDescription,
    platonicQuestionText,
    questionGids,
  });
}

export function getQuestionGid({ gid }) {
  return axios.get(`/api/staff/question_tags/gids/${gid}/`);
}

export function getQuestionTag({ tagId }) {
  return axios.get(`/api/staff/question_tags/${tagId}/`);
}

export function editQuestionTag({
  tagId,
  tag,
  tagDisplay,
  tagDescription,
  platonicQuestionText,
  questionGids,
}) {
  return axios.patch(`/api/staff/question_tags/${tagId}/edit/`, {
    tag,
    tagDisplay,
    tagDescription,
    platonicQuestionText,
    questionGids,
  });
}

export function deleteQuestionTag({ tagId }) {
  return axios.delete(`/api/staff/question_tags/${tagId}/delete/`);
}

export function questionTagExport() {
  return axios.get('/api/staff/question_tags/export/');
}

export function getQuestionTagExport({ exportId }) {
  return axios.get(`/api/staff/question_tags/export/${exportId}/`);
}

export function allOrganizations() {
  return axios.get('/staff/api/organization/list/all/');
}

export function organizations() {
  return axios.get('/staff/api/organization/list/');
}

export function subDocTypeList() {
  return axios.get('/staff/api/sub_doc_type/list/');
}

export function staffFundCreate({
  fundName,
  organizationName,
  organizationId,
  managedDiligenceEnabled,
  diligenceReviewerEmail,
}) {
  return axios.post('/staff/api/fund/create/', {
    fundName,
    organizationName,
    organizationId,
    managedDiligenceEnabled,
    diligenceReviewerEmail,
  });
}

export function listQuestionnaires() {
  return axios.get('/staff/api/questionnaires/list');
}

export function subscriptionDocumentCreate({
  fundId,
  subDocType,
  fileId,
  useOnboardingV2,
  name,
  templateQuestionnaireId,
}) {
  return axios.post(`/staff/api/fund/${fundId}/subscription_document/create/`, {
    subDocType,
    fileId,
    useOnboardingV2,
    name,
    templateQuestionnaireId,
  });
}

export function getFundStaffToolsData({ fundId }) {
  return axios.get(`/staff/api/fund/${fundId}/staff_tools/`);
}

export function getCustomApprovalData({ fundId }) {
  return axios.get(`/staff/api/fund/${fundId}/custom-approval/`);
}

export function publishCustomApprovalData({ fundId, data }) {
  return axios.put(`/staff/api/fund/${fundId}/custom-approval/`, {
    graph: data,
  });
}

export function deleteCustomApprovalData({ fundId }) {
  return axios.delete(`/staff/api/fund/${fundId}/custom-approval/`);
}

export function getEmailReport() {
  return '/staff/api/email/report/';
}

export function listDiligenceReviewers() {
  return axios.get('/staff/api/diligence-reviewers/list/');
}

export function enableManagedDiligence({ fundId, diligenceReviewerEmail }) {
  return axios.post(`/staff/api/fund/${fundId}/enable_managed_diligence/`, {
    diligenceReviewerEmail,
  });
}

export function socialLogin({ email }) {
  return axios.post('/social-auth/login/', { email });
}

export function canUseSocialAuth({ email }) {
  return axios.post('/social-auth/check/', { email });
}

export function updateClientMatterNumber({
  organizationId,
  fundId,
  clientMatterNumber,
}) {
  return axios.patch(
    `/api/organization/${organizationId}/fund/${fundId}/client_matter_number/`,
    { clientMatterNumber },
  );
}

export function orgFundMemberCreate({
  organizationId,
  fundId,
  name,
  email,
  level,
}) {
  return axios.post(
    `/api/organization/${organizationId}/fund/${fundId}/member/create/`,
    {
      name,
      email,
      level,
    },
  );
}

export function orgFundMemberUpdate({
  organizationId,
  fundId,
  memberId,
  level,
}) {
  return axios.patch(
    `/api/organization/${organizationId}/fund/${fundId}/member/${memberId}/update/`,
    {
      level,
    },
  );
}

export function orgFundMemberDelete({ organizationId, fundId, memberId }) {
  return axios.delete(
    `/api/organization/${organizationId}/fund/${fundId}/member/${memberId}/delete/`,
  );
}

export function orgFundMemberCount({ organizationId }) {
  return axios.get(`/api/organization/${organizationId}/fund/member/count/`);
}

export function orgVerifiedDomainList({ organizationId }) {
  return axios.get(`/api/organization/${organizationId}/verified-domain/list/`);
}

export function orgVerifiedDomainCreate({ organizationId, emailDomain }) {
  return axios.post(
    `/api/organization/${organizationId}/verified-domain/create/`,
    {
      emailDomain,
    },
  );
}

export function orgVerifiedDomainDelete({ organizationId, domainId }) {
  return axios.delete(
    `/api/organization/${organizationId}/verified-domain/${domainId}/delete/`,
  );
}

export function orgVerifiedDomainVerify({ organizationId, domainId }) {
  return axios.post(
    `/api/organization/${organizationId}/verified-domain/${domainId}/verify/`,
  );
}

export function orgApprovedDomainCreate({ organizationId, emailDomain }) {
  return axios.patch(
    `/api/organization/${organizationId}/approved-domain/create/`,
    {
      emailDomain,
    },
  );
}

export function orgApprovedDomainDelete({ organizationId, emailDomain }) {
  return axios.patch(
    `/api/organization/${organizationId}/approved-domain/delete/`,
    {
      emailDomain,
    },
  );
}

export function orgTrustedDomainList({ organizationId }) {
  return axios.get(`/api/organization/${organizationId}/trusted-domain/list/`);
}

export function orgTrustedDomainCreate({ organizationId, domain }) {
  return axios.post(
    `/api/organization/${organizationId}/trusted-domain/create/`,
    {
      domain,
    },
  );
}

export function orgTrustedDomainDelete({ organizationId, domainId }) {
  return axios.delete(
    `/api/organization/${organizationId}/trusted-domain/${domainId}/delete/`,
  );
}

export function orgDomainDelete({ organizationId, domain }) {
  return axios.patch(`/api/organization/${organizationId}/domain/delete/`, {
    domain,
  });
}

export function universalSearch({ searchTerm, organizationId }) {
  return axios.get('/api/universal-search/', {
    params: {
      search_term: searchTerm,
      organization_id: organizationId,
    },
  });
}

export function getTraySolutions({ organizationId }) {
  return axios.get(
    `/public/api/internal/organizations/${organizationId}/solutions/`,
  );
}

export function getIntegrationCards({ organizationId }) {
  return axios.get(
    `/public/api/internal/organizations/${organizationId}/integrations/card/`,
  );
}

export function enableIntegrationCard({ organizationId, cardId }) {
  return axios.post(
    `/public/api/internal/organizations/${organizationId}/integrations/card/${cardId}/`,
  );
}

export function deactivateIntegrationCard({ organizationId, cardId }) {
  return axios.delete(
    `/public/api/internal/organizations/${organizationId}/integrations/card/${cardId}/`,
  );
}

export function validateTray({ organizationId, instanceId, data }) {
  return axios.post(
    `/public/api/internal/organizations/${organizationId}/solutions/${instanceId}/validate/`,
    data,
  );
}

export function connectTray({ organizationId, solutionId }) {
  return axios.post(
    `/public/api/internal/organizations/${organizationId}/solutions/${solutionId}/`,
  );
}

export function enableTray({ organizationId, solutionId }) {
  return axios.put(
    `/public/api/internal/organizations/${organizationId}/solutions/${solutionId}/`,
  );
}

export function removeTray({ organizationId, solutionId }) {
  return axios.delete(
    `/public/api/internal/organizations/${organizationId}/solutions/${solutionId}/`,
  );
}

export function copyQuestionnaire({
  from: fromQuestionnaireId,
  to: toQuestionnaireId,
  fundId,
}) {
  return axios.post(`/staff/api/fund/${fundId}/copy_questionnaire/`, {
    fromQuestionnaireId,
    toQuestionnaireId,
  });
}

export function skipDiligence({ fundId, lpClosingId, diligenceId }) {
  return axios.delete(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence/${diligenceId}/skip/`,
  );
}

export function waiveDiligence({ fundId, lpClosingId, diligenceId }) {
  return axios.patch(
    `/api/fund/${fundId}/lp_closing/${lpClosingId}/diligence/${diligenceId}/waive/`,
  );
}

export function getAddressAutocomplete({
  input,
  country,
  location,
  sessionToken,
}) {
  return axios.post('/api/places/autocomplete/', {
    input,
    country,
    location: location?.toString(),
    session_token: sessionToken,
  });
}

export function getAddressDetails({ placeId, sessionToken }) {
  return axios.post('/api/places/details/', {
    place_id: placeId,
    session_token: sessionToken,
  });
}

export function sendForBulkCountersign({
  fundId,
  closingId,
  lpDocumentIds,
  countersignerId,
  countersigner2Id,
  countersigner3Id,
}) {
  return axios.put(
    `/api/fund/${fundId}/closing/${closingId}/bulk-countersign/send/`,
    {
      lpDocumentIds,
      countersignerId,
      countersigner2Id,
      countersigner3Id,
    },
  );
}

export function bulkCountersignGpFieldsList({
  fundId,
  closingId,
  lpDocumentIds,
}) {
  return axios.post(
    `/api/fund/${fundId}/closing/${closingId}/bulk-countersign/gp-fields/list/`,
    lpDocumentIds,
  );
}

export function bulkCountersignGpFieldsUpdate({
  fundId,
  closingId,
  skipValidation,
  lpDocuments,
}) {
  return axios.post(
    `/api/fund/${fundId}/closing/${closingId}/bulk-countersign/gp-fields/update/`,
    {
      skipValidation,
      lpDocuments,
    },
  );
}

export function bulkCountersignLpDocumentsForEnvelopeList({
  fundId,
  envelopeId,
}) {
  return axios.get(`/api/fund/${fundId}/envelope/${envelopeId}/list/`);
}

export function getBoxesForSignaturePlacement({
  fundId,
  closingId,
  lpDocumentId,
}) {
  return axios.get(
    `/api/fund/${fundId}/closing/${closingId}/lpdocument/${lpDocumentId}/signature-boxes/`,
  );
}

export function placeSignatureBoxes({
  fundId,
  closingId,
  lpDocumentId,
  boxes,
}) {
  return axios.post(
    `/api/fund/${fundId}/closing/${closingId}/lpdocument/${lpDocumentId}/update-boxes/`,
    { gpFields: boxes },
  );
}

export async function getGeolocation() {
  // can't use axios due to custom interceptors
  const response = await fetch(
    `https://www.googleapis.com/geolocation/v1/geolocate?key=${window.GOOGLE_MAPS_API_KEY}`,
    {
      method: 'POST',
      body: JSON.stringify({ considerIp: true }),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const data = await response.json();
  return { data };
}

export function getUserRegionRedirect() {
  return axios.get('/api/me/region-redirect/');
}

export function staffUsersList() {
  return axios.get('/staff/api/staff/users/');
}

export function diligenceIssueList() {
  return axios.get('/staff/api/staff/diligence/');
}
