import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import { useFunds } from 'services/providers/funds';
import { useMe } from 'services/providers/me';
import * as urls from 'services/urls';
import * as api from 'services/api';
import { getSelectedStaffOrg } from 'services/utils';
import { useWhiteLabelConfig } from 'services/providers/theme';
import { PageContainer } from 'components/page_container';
import { FundTable } from 'components/fund_table/index';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  headerText: {
    fontSize: '2rem',
    fontWeight: 500,
  },
}));

function MainFundTable() {
  const [funds, , refresh] = useFunds();
  const [me] = useMe();
  const selectedStaffOrg = getSelectedStaffOrg();
  const [commitments, setCommitments] = useState([]);
  const whiteLabelConfig = useWhiteLabelConfig();

  useEffect(refresh, []);

  let fundsToDisplay = funds;
  if (me.isStaff && fundsToDisplay !== null) {
    fundsToDisplay = fundsToDisplay.filter(
      (fund) => fund.organizationId === selectedStaffOrg,
    );
  } else if (
    whiteLabelConfig?.organizationIds?.length &&
    fundsToDisplay !== null
  ) {
    fundsToDisplay = fundsToDisplay.filter((fund) =>
      whiteLabelConfig.organizationIds.includes(fund.organizationId),
    );
  }

  function getCommitments() {
    const batchedFundIds = [];
    const chunkSize = 10;
    if (!fundsToDisplay) return;
    for (let i = 0; i < fundsToDisplay.length; i += chunkSize) {
      batchedFundIds.push(
        fundsToDisplay.slice(i, i + chunkSize).map((fund) => fund.id),
      );
    }
    for (let i = 0; i < batchedFundIds.length; i += 1) {
      api
        .fundListCommitment({
          batchedFundIds: batchedFundIds[i],
        })
        .then((response) => {
          setCommitments((prevCommitments) => [
            ...prevCommitments,
            ...response.data,
          ]);
        });
    }
  }

  useEffect(() => {
    getCommitments();
  }, [funds, selectedStaffOrg]);

  const shouldRedirect =
    fundsToDisplay !== null &&
    fundsToDisplay.length === 1 &&
    (me.isStaff || !fundsToDisplay[0].externalAccessPaused);

  if (shouldRedirect) {
    const fundId = fundsToDisplay[0].id;
    return <Redirect to={urls.fundUrl({ fundId })} />;
  }

  return <FundTable funds={fundsToDisplay} commitments={commitments} />;
}

export function FundListPage() {
  const classes = useStyles();

  return (
    <PageContainer>
      <div className={classes.headerContainer}>
        <Typography className={classes.headerText}>Funds</Typography>
      </div>
      <MainFundTable />
    </PageContainer>
  );
}
