import React, { useState, useEffect } from 'react';
import { useHistory, Prompt } from 'react-router-dom';
import { GPSelectionInput } from 'components/gp_selection_input/index';
import { Spinner } from 'components/spinner';
import * as constants from 'components/gp_selection_input/constants';
import { Modal, Alert, Button } from '@passthrough/uikit';
import { useToast } from 'services/toast';
import * as api from 'services/api';
import * as urls from 'services/urls';

import { useSupport } from 'components/support';
import {
  JurisdictionSelect,
  JURISDICTIONS,
} from 'components/jurisdiction_select';
import { DiscardAlert } from '../discard';

export function NewJurisdictionDialog({
  fundId,
  managedDiligenceEnabled,
  open,
  onClose,
  members,
  isLoadingMembers,
}) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const { successToast } = useToast();
  const { showSupport } = useSupport();

  const [discardOpen, setDiscardOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const changed = Object.keys(form).length > 0;

  function handleClose() {
    if (changed) {
      setDiscardOpen(true);
    } else {
      onClose();
    }
  }

  const possibleDiligenceReviewers = managedDiligenceEnabled
    ? members?.filter((member) => member.canReviewManagedDiligence)
    : members;

  const missingRequiredFields = !form.diligenceJurisdiction;

  useEffect(() => {
    const cleanup = () => {
      setForm({});
      setErrors({});
      setSubmitted(false);
    };
    return cleanup;
  }, [open]);

  function handleSubmit() {
    const jurisdictionDisplayName = JURISDICTIONS.find(
      (j) => j.id === form.diligenceJurisdiction,
    )?.name;
    if (!jurisdictionDisplayName) {
      setErrors({
        diligenceJurisdiction: 'This field is required.',
      });
      return;
    }
    const name = `${jurisdictionDisplayName} Diligence`;
    const submitForm = {
      ...form,
      name,
      diligenceEnabled: true,
    };

    setSubmitted(true);
    setLoading(true);
    api
      .createClosing({ fundId, ...submitForm })
      .then((response) => {
        successToast(`Added jurisdiction: ${submitForm.name}`);
        history.push(urls.closingUrl({ fundId, closingId: response.data.id }));
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setErrors(error.response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function renderContent() {
    return (
      <>
        <Prompt
          message="Your changes have not been saved. Are you sure you want to leave?"
          when={!submitted && changed}
        />
        {errors.diligenceEnabled ? (
          <Alert
            severity="error"
            action={
              <Button
                variant="text"
                external
                onClick={showSupport}
                size="small"
              >
                Contact support
              </Button>
            }
          >
            Diligence closings are not enabled for this fund.
          </Alert>
        ) : null}
        {errors.nonFieldErrors ? (
          <Alert severity="error">{errors.nonFieldErrors}</Alert>
        ) : null}

        <JurisdictionSelect
          jurisdiction={form.diligenceJurisdiction}
          jurisdictionError={errors.diligenceJurisdiction}
          onChange={(e) => {
            setForm((f) => ({
              ...f,
              diligenceJurisdiction: e.target.value,
            }));
          }}
        />
        <GPSelectionInput
          label="Diligence reviewer"
          errorMsg={errors.defaultDiligenceApproverId}
          value={form.defaultDiligenceApproverId}
          setValue={(id) => {
            setForm((f) => ({
              ...f,
              defaultDiligenceApproverId: id,
            }));
          }}
          roleLabelNum={constants.DEFAULT_DILIGENCE_APPROVER_LABEL_NUM}
          members={possibleDiligenceReviewers}
          fundId={fundId}
          includeBlankOption
          required
        />
      </>
    );
  }

  return (
    <>
      <DiscardAlert
        open={discardOpen}
        onDiscard={() => {
          setDiscardOpen(false);
          onClose();
        }}
        onClose={() => setDiscardOpen(false)}
      />

      <Modal
        open={open}
        onClose={handleClose}
        headerLabel="Add jurisdiction"
        size="sm"
        primaryButtonText="Add"
        onSubmit={handleSubmit}
        showCancelButton
        primaryButtonLoading={loading}
        primaryButtonDisabled={missingRequiredFields}
        primaryButtonProps={{
          htmlProps: {
            'data-test': 'create',
          },
        }}
      >
        {isLoadingMembers ? <Spinner fullScreen /> : renderContent()}
      </Modal>
    </>
  );
}
