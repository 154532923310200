import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { DiligenceFileInput } from './components/file';
import { IndividualDetails } from './components/details/individual';
import { RootSourceOfWealthDetails } from './subsection_question_display/details_subsection/jurisdiction_specific_details/root_source_of_wealth_data';
import { NameAndTypeInputs } from './components/name_and_type_inputs';
import { OptOut } from './subsection_question_display/opt_out';
import { jurisdictions } from './constants';
import { getDocumentFields, isDocumentsSectionComplete } from './utils';

const useStyles = makeStyles((theme) => ({
  detailInputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
}));

export function RootIndividualQuestion({
  lpClosingId,
  form,
  formErrors,
  fundName,
  hideOptOut,
  jurisdiction,
  handleChange,
  changeType,
  QuestionPromptComponent,
  preventLegalNameChange,
  toLegalName,
}) {
  const classes = useStyles();
  const {
    optOut,
    name,
    type,
    dob,
    nationality,
    ssn,
    useSsn,
    residentialAddress,
    sourceOfWealthDetails,
    sourceOfWealth,
  } = form;
  const {
    name: nameError,
    type: typeError,
    dob: dobError,
    nationality: nationalityError,
    ssn: ssnError,
    residentialAddress: residentialAddressError,
    sourceOfWealth: sourceOfWealthError,
    sourceOfWealthDetails: sourceOfWealthDetailsError,
  } = formErrors;

  const rootIndividualDocFields = getDocumentFields(type, jurisdiction, true);
  const shouldDisableOptOut = isDocumentsSectionComplete(
    form,
    rootIndividualDocFields,
  );
  const showRootSourceOfWealthFields =
    jurisdiction === jurisdictions.CAYMAN ||
    jurisdiction === jurisdictions.SEI ||
    jurisdiction === jurisdictions.LUXEMBOURG;

  const fileUploadUrl = `/api/lpclosing/${lpClosingId}/file/upload/`;

  return (
    <>
      <QuestionPromptComponent short />

      <div className={classes.detailInputsContainer}>
        <NameAndTypeInputs
          jurisdiction={jurisdiction}
          name={name}
          nameError={nameError}
          type={type}
          typeError={typeError}
          handleChange={handleChange}
          changeType={changeType}
          preventLegalNameChange={preventLegalNameChange}
          toLegalName={toLegalName}
        />

        <IndividualDetails
          dob={dob}
          dobError={dobError}
          exposeNationality={jurisdiction === jurisdictions.SEI}
          nationality={nationality}
          nationalityError={nationalityError}
          ssn={ssn}
          ssnError={ssnError}
          useSsn={useSsn}
          residentialAddress={residentialAddress}
          residentialAddressError={residentialAddressError}
          handleChange={handleChange}
          taxIDLabel={
            jurisdiction === jurisdictions.LUXEMBOURG
              ? 'TIN or Passport number'
              : 'Other Tax ID'
          }
          disableFlip={jurisdiction === jurisdictions.LUXEMBOURG}
        />

        {showRootSourceOfWealthFields ? (
          <RootSourceOfWealthDetails
            sourceOfWealthDetails={sourceOfWealthDetails}
            sourceOfWealth={sourceOfWealth}
            sourceOfWealthError={sourceOfWealthError}
            sourceOfWealthDetailsError={sourceOfWealthDetailsError}
            handleChange={handleChange}
          />
        ) : null}

        {rootIndividualDocFields.map((field) => (
          <DiligenceFileInput
            key={field.key}
            file={form[field.key]}
            fileError={formErrors[field.key]}
            fileUploadUrl={fileUploadUrl}
            onChange={(newFile) => {
              handleChange(field.key, newFile);
            }}
            formLabel={field.label}
            helpText={field.helpText}
          />
        ))}

        {hideOptOut ? null : (
          <OptOut
            nodeName={name}
            fundName={fundName}
            optOut={optOut}
            handleChange={handleChange}
            shouldDisable={shouldDisableOptOut}
          />
        )}
      </div>
    </>
  );
}
