import React from 'react';
import { Typography } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';

import * as constants from 'components/gp_selection_input/constants';
import { GPSelectionInput } from 'components/gp_selection_input/index';
import { JURISDICTIONS } from 'components/jurisdiction_select';

const useStyles = makeStyles(() => ({
  jurisdictionTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
}));

export function DiligenceSettings({
  diligenceJurisdiction,
  diligenceApproverId,
  setDiligenceApproverId,
  diligenceApproverIdError,
  members,
  fundId,
}) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="card-heading">Diligence settings</Typography>

      <div className={classes.jurisdictionTypeContainer}>
        <Typography variant="body" size="small">
          Jurisdiction
        </Typography>

        <Typography variant="body" size="medium">
          {JURISDICTIONS.find((j) => j.id === diligenceJurisdiction).name}
        </Typography>
      </div>

      <GPSelectionInput
        label="Diligence reviewer"
        errorMsg={diligenceApproverIdError}
        value={diligenceApproverId}
        setValue={setDiligenceApproverId}
        roleLabelNum={constants.DEFAULT_DILIGENCE_APPROVER_LABEL_NUM}
        members={members}
        fundId={fundId}
        fundPermissionLevel={constants.ACCESS_LEVEL_REVIEW}
        includeBlankOption
        required
      />
    </>
  );
}
