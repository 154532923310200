// @ts-check - for IDE only
import React from 'react';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import { formatDistanceStrict } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Icons, UserDisplay } from '@passthrough/uikit';
import * as constants from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoIcon: {
    color: theme.palette.text.secondary,
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

const useChipStyles = makeStyles((theme) => ({
  circle: {
    minWidth: '8px',
    width: '8px',
    height: '8px',
    borderRadius: '8px',
    marginRight: theme.spacing(1),
  },
  draft: {
    backgroundColor: theme.palette.investorClosingStatus.unsent,
  },
  notStarted: {
    backgroundColor: theme.palette.investorClosingStatus.notStarted,
  },
  waiting: {
    backgroundColor: theme.palette.investorClosingStatus.sent,
  },
  signed: {
    backgroundColor: theme.palette.investorClosingStatus.signed,
  },
  approved: {
    backgroundColor: theme.palette.investorClosingStatus.approved,
  },
  done: {
    backgroundColor: theme.palette.investorClosingStatus.executed,
  },
}));

function inProgressSubStatus(lpClosing) {
  const { sentForSignature } = lpClosing;

  if (sentForSignature) {
    return constants.SENT_FOR_SIGNATURE_STATUS;
  }

  return constants.IN_PROGRESS_STATUS;
}

function partiallySignedSubStatus(lpClosing) {
  const { waitingOnFirstSigner } = lpClosing;

  if (waitingOnFirstSigner) {
    return constants.PARTIALLY_SIGNED_FIRST_SIGNER_STATUS;
  }

  return constants.PARTIALLY_SIGNED_STATUS;
}

function signedSubStatus(lpClosing) {
  const {
    hasSigning,
    hasLpRequestedReview,
    questionnaireApproved,
    diligenceApproved,
    isDiligenceOnly,
    customState,
  } = lpClosing;

  if (customState?.statusText) {
    return constants.CUSTOM_APPROVAL_SIGNED_STATUS;
  }

  if (hasLpRequestedReview) {
    return constants.LP_REQUESTED_REVIEW_STATUS;
  }

  if (diligenceApproved && (questionnaireApproved || isDiligenceOnly)) {
    return constants.READY_TO_APPROVE_STATUS;
  }

  if (questionnaireApproved) {
    return constants.QUESTIONNAIRE_APPROVED_STATUS;
  }

  if (diligenceApproved) {
    return constants.DILIGENCE_APPROVED_STATUS;
  }

  if (hasSigning) {
    return constants.SIGNED_STATUS;
  }

  return constants.INVESTOR_COMPLETED_STATUS;
}

function approvedSubStatus(lpClosing) {
  const { pendingPreparation } = lpClosing;

  if (pendingPreparation) {
    return constants.APPROVED_PENDING_PREPARATION_STATUS;
  }

  return constants.APPROVED_STATUS;
}

function countersignedSubStatus(lpClosing) {
  const { fullyExecutedEmailSent } = lpClosing;

  if (fullyExecutedEmailSent) {
    return constants.CLOSING_EMAIL_SENT_STATUS;
  }

  return constants.COUNTERSIGNED_STATUS;
}

function getStatusForLpClosing(lpClosing) {
  switch (lpClosing.status) {
    case constants.IN_PROGRESS_STATUS:
      return inProgressSubStatus(lpClosing);
    case constants.PARTIALLY_SIGNED_STATUS:
      return partiallySignedSubStatus(lpClosing);
    case constants.SIGNED_STATUS:
      return signedSubStatus(lpClosing);
    case constants.APPROVED_STATUS:
      return approvedSubStatus(lpClosing);
    case constants.COUNTERSIGNED_STATUS:
      return countersignedSubStatus(lpClosing);
    default:
      return lpClosing.status;
  }
}

export function StatusDot({ color }) {
  const classes = useChipStyles();
  const { StatusColor } = constants;

  return (
    <div
      className={clsx(classes.circle, {
        [classes.draft]: color === StatusColor.DRAFT,
        [classes.notStarted]: color === StatusColor.NOT_STARTED,
        [classes.waiting]: color === StatusColor.WAITING,
        [classes.signed]: color === StatusColor.SIGNED,
        [classes.approved]: color === StatusColor.APPROVED,
        [classes.done]: color === StatusColor.DONE,
      })}
    />
  );
}

function formatTimeAgo(date) {
  const now = new Date();
  const then = new Date(date);
  now.setHours(0, 0, 0, 0);
  then.setHours(0, 0, 0, 0);

  let timeAgo = '';
  if (date && then >= now) {
    timeAgo = 'today';
  } else if (date && then < now) {
    timeAgo = formatDistanceStrict(then, now, { addSuffix: true });
  }
  return timeAgo;
}

function customApprovalStatusInfo(lpClosing) {
  const { customState } = lpClosing;
  const { statusText, sortOrder } = customState;
  const { STATUS_INFO, CUSTOM_APPROVAL_SIGNED_STATUS } = constants;

  const baseInfo = STATUS_INFO[CUSTOM_APPROVAL_SIGNED_STATUS];

  return {
    ...baseInfo,
    label: statusText,
    order:
      baseInfo.order + sortOrder / constants.CUSTOM_APPROVAL_SORT_ORDER_DIVISOR,
  };
}

const OMIT_SENT_STATUSES = [
  constants.SENT_FOR_SIGNATURE_STATUS,
  constants.REQUESTED_CHANGES_STATUS,
];

const SENT_REMINDER_STATUSES = [
  constants.VIEWED_STATUS,
  constants.UNVIEWED_STATUS,
  constants.IN_PROGRESS_STATUS,
  constants.SENT_FOR_SIGNATURE_STATUS,
  constants.REQUESTED_CHANGES_STATUS,
];

const COMPLETED_DATE_STATUSES = [
  constants.SIGNED_STATUS,
  constants.INVESTOR_COMPLETED_STATUS,
  constants.LP_REQUESTED_REVIEW_STATUS,
];

const FULLY_EXECUTED_EMAIL_SENT_STATUSES = [
  constants.CLOSING_EMAIL_SENT_STATUS,
];

function extraStatusInfo(lpClosing, computedStatus) {
  let event = null;
  let timeAgo = null;

  const { lastSentEvent, sentDate, completedDate, fullyExecutedEmailSent } =
    lpClosing;

  if (
    lastSentEvent === 'SENT_REMINDER_TO_INVESTOR' &&
    SENT_REMINDER_STATUSES.includes(computedStatus)
  ) {
    event = 'Sent reminder';
    timeAgo = sentDate;
  } else if (SENT_REMINDER_STATUSES.includes(computedStatus)) {
    event = OMIT_SENT_STATUSES.includes(computedStatus) ? null : 'Sent';
    timeAgo = sentDate;
  } else if (COMPLETED_DATE_STATUSES.includes(computedStatus)) {
    event = null;
    timeAgo = completedDate;
  } else if (FULLY_EXECUTED_EMAIL_SENT_STATUSES.includes(computedStatus)) {
    event = null;
    timeAgo = fullyExecutedEmailSent;
  }

  return { event, timeAgo };
}

// Returns an object containing:
//  {
//    label: string,
//    color: StatusColor,
//    tooltipText: string | undefined,
//    timeAgo: date | timestamp | null,
//    event: string | null,
//    order: number,
//  }
//
// Assumes that the lpClosing was rendered by LPClosingStatusSerializer
// on the backend.
export function getLPClosingStatusInfo(lpClosing) {
  const status = getStatusForLpClosing(lpClosing);
  const baseInfo =
    status === constants.CUSTOM_APPROVAL_SIGNED_STATUS
      ? customApprovalStatusInfo(lpClosing)
      : constants.STATUS_INFO[status];

  return {
    ...baseInfo,
    ...extraStatusInfo(lpClosing, status),
  };
}

export function StatusChip({
  label,
  color,
  tooltipText = null,
  size = 'medium',
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <StatusDot color={color} />

      <div className={classes.labelContainer}>
        <Typography
          variant="body"
          data-test="lp_closing_status_text"
          size={size}
        >
          {label}
        </Typography>

        {tooltipText ? (
          <Tooltip
            title={<Typography variant="label">{tooltipText}</Typography>}
          >
            <Icons.InfoOutlined className={classes.infoIcon} fontSize="small" />
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
}

function getAmendedLabel(label, event, timeAgo, showTimeAgo) {
  let amendedLabel = label;
  if (event) {
    amendedLabel = `${label} — ${event}`;
  }
  if (showTimeAgo && timeAgo) {
    amendedLabel = `${amendedLabel} ${formatTimeAgo(timeAgo)}`;
  }
  return amendedLabel;
}

export function LPClosingStatusChip({
  lpClosing,
  showTimeAgo = true,
  size = 'medium',
}) {
  const { label, color, tooltipText, timeAgo, event } =
    getLPClosingStatusInfo(lpClosing);

  return (
    <StatusChip
      label={getAmendedLabel(label, event, timeAgo, showTimeAgo)}
      color={color}
      tooltipText={tooltipText}
      size={size}
    />
  );
}

export function LPStatusText({ status, task }) {
  const { hasLpRequestedReview } = task;
  if (hasLpRequestedReview) {
    return <>Pending review</>;
  }
  if (constants.notStartedStatuses.includes(status)) {
    return <>Not started</>;
  }
  if (constants.inProgressStatuses.includes(status)) {
    return <>In progress</>;
  }
  if (status === constants.PARTIALLY_SIGNED_STATUS) {
    const { signer } = task;
    return (
      <>
        Waiting on{' '}
        <UserDisplay name={signer.name} email={signer.email} variant="inline" />
      </>
    );
  }
  return <>Completed</>;
}
