/* eslint-disable import/no-unused-modules */
// "Real" statuses, lpClosing.status can be any one of these, can be
// used by any code.
export const DRAFT_STATUS = 'DRAFT';
export const UNVIEWED_STATUS = 'UNVIEWED';
export const VIEWED_STATUS = 'VIEWED';
export const IN_PROGRESS_STATUS = 'IN_PROGRESS';
export const REQUESTED_CHANGES_STATUS = 'REQUESTED_CHANGES';
export const PARTIALLY_SIGNED_STATUS = 'PARTIALLY_SIGNED';
export const SIGNED_STATUS = 'SIGNED';
export const APPROVED_STATUS = 'APPROVED';
export const SENT_TO_COUNTERSIGNER_STATUS = 'SENT_TO_COUNTERSIGNER';
export const COUNTERSIGNED_STATUS = 'COUNTERSIGNED';

// Sub-statuses, should only be directly used by status/index.jsx.
// in progress
export const SENT_FOR_SIGNATURE_STATUS = 'SENT_FOR_SIGNATURE';
// partially signed
export const PARTIALLY_SIGNED_FIRST_SIGNER_STATUS =
  'PARTIALLY_SIGNED_FIRST_SIGNER';
// signed
export const CUSTOM_APPROVAL_SIGNED_STATUS = 'CUSTOM_APPROVAL_SIGNED';
export const LP_REQUESTED_REVIEW_STATUS = 'LP_REQUESTED_REVIEW';
export const READY_TO_APPROVE_STATUS = 'READY_TO_APPROVE';
export const QUESTIONNAIRE_APPROVED_STATUS = 'QUESTIONNAIRE_APPROVED';
export const DILIGENCE_APPROVED_STATUS = 'DILIGENCE_APPROVED';
export const INVESTOR_COMPLETED_STATUS = 'INVESTOR_COMPLETED';
// approved
export const APPROVED_PENDING_PREPARATION_STATUS =
  'APPROVED_PENDING_PREPARATION';
// countersigned
export const CLOSING_EMAIL_SENT_STATUS = 'CLOSING_EMAIL_SENT';
/* eslint-enable import/no-unused-modules */

export const canSendToInvestorStatuses = [DRAFT_STATUS];

export const multiDeletableStatuses = [
  DRAFT_STATUS,
  UNVIEWED_STATUS,
  VIEWED_STATUS,
  IN_PROGRESS_STATUS,
];

export const canRemindStatuses = [
  UNVIEWED_STATUS,
  VIEWED_STATUS,
  IN_PROGRESS_STATUS,
  PARTIALLY_SIGNED_STATUS,
  REQUESTED_CHANGES_STATUS,
];

export const canApproveStatus = [SIGNED_STATUS];

export const canUnapproveStatuses = [
  APPROVED_STATUS,
  SENT_TO_COUNTERSIGNER_STATUS,
  COUNTERSIGNED_STATUS,
  CLOSING_EMAIL_SENT_STATUS,
];

export const inProgressStatuses = [
  DRAFT_STATUS,
  UNVIEWED_STATUS,
  VIEWED_STATUS,
  IN_PROGRESS_STATUS,
  REQUESTED_CHANGES_STATUS,
];

export const notStartedStatuses = [
  DRAFT_STATUS,
  UNVIEWED_STATUS,
  VIEWED_STATUS,
];

export const signedStatuses = [SIGNED_STATUS, PARTIALLY_SIGNED_STATUS];

export const canSendToCountersignerStatuses = [APPROVED_STATUS];

export const canMarkAsFullyExecutedStatuses = [APPROVED_STATUS];

export const canResendClosingEmailStatuses = [COUNTERSIGNED_STATUS];

export const canUndoRequestChangesStatuses = [REQUESTED_CHANGES_STATUS];

export const canPrepareSignaturePagesStatuses = [
  SIGNED_STATUS,
  APPROVED_STATUS,
];

export const StatusColor = {
  DRAFT: 0,
  NOT_STARTED: 1,
  WAITING: 2,
  SIGNED: 3,
  APPROVED: 5,
  DONE: 6,
};

// Since custom approval sort order is computed like
// order + customSortOrder / 1000, and the difference between
// custom approval order and the next status is 10, the max custom order
// without breaking sorting is 9999. This is enforced on the backend.
export const CUSTOM_APPROVAL_SORT_ORDER_DIVISOR = 1000;

export const STATUS_INFO = {
  [DRAFT_STATUS]: {
    label: 'Unsent',
    color: StatusColor.DRAFT,
    order: 0,
  },
  [UNVIEWED_STATUS]: {
    label: 'Not started',
    color: StatusColor.NOT_STARTED,
    order: 10,
  },
  [VIEWED_STATUS]: {
    label: 'Not started',
    color: StatusColor.NOT_STARTED,
    // Same as UNVIEWED on purpose, they should be sorted equally
    order: 10,
  },
  [IN_PROGRESS_STATUS]: {
    label: 'In progress',
    color: StatusColor.WAITING,
    order: 30,
  },
  [SENT_FOR_SIGNATURE_STATUS]: {
    label: 'Sent for investor signature',
    color: StatusColor.WAITING,
    order: 40,
  },
  [REQUESTED_CHANGES_STATUS]: {
    label: 'Requested changes',
    color: StatusColor.WAITING,
    order: 50,
  },
  [PARTIALLY_SIGNED_FIRST_SIGNER_STATUS]: {
    label: 'Sent — Waiting on first signer',
    color: StatusColor.WAITING,
    order: 60,
  },
  [PARTIALLY_SIGNED_STATUS]: {
    label: 'Sent — Waiting on additional signer',
    color: StatusColor.WAITING,
    order: 70,
  },
  [LP_REQUESTED_REVIEW_STATUS]: {
    label: 'Investor requested review',
    color: StatusColor.SIGNED,
    order: 80,
  },
  [INVESTOR_COMPLETED_STATUS]: {
    label: 'Investor completed',
    color: StatusColor.SIGNED,
    order: 90,
  },
  [SIGNED_STATUS]: {
    label: 'Investor signed',
    color: StatusColor.SIGNED,
    order: 100,
  },
  [DILIGENCE_APPROVED_STATUS]: {
    label: 'Diligence reviewed',
    color: StatusColor.SIGNED,
    order: 110,
  },
  [QUESTIONNAIRE_APPROVED_STATUS]: {
    label: 'Questionnaire approved',
    color: StatusColor.SIGNED,
    order: 120,
  },
  [READY_TO_APPROVE_STATUS]: {
    label: 'Ready to approve investor',
    color: StatusColor.SIGNED,
    order: 130,
  },
  // Special case, label and order are set dynamically
  [CUSTOM_APPROVAL_SIGNED_STATUS]: {
    label: '',
    color: StatusColor.SIGNED,
    order: 140,
  },
  [APPROVED_PENDING_PREPARATION_STATUS]: {
    label: 'Approved — Pending preparation',
    color: StatusColor.APPROVED,
    order: 150,
    tooltipText:
      'Fields for the countersigner have not been placed in the uploaded document',
  },
  [APPROVED_STATUS]: {
    label: 'Approved',
    color: StatusColor.APPROVED,
    order: 160,
  },
  [SENT_TO_COUNTERSIGNER_STATUS]: {
    label: 'Sent to countersigner',
    color: StatusColor.WAITING,
    order: 170,
  },
  [COUNTERSIGNED_STATUS]: {
    label: 'Fully executed',
    color: StatusColor.DONE,
    order: 180,
  },
  [CLOSING_EMAIL_SENT_STATUS]: {
    label: 'Closing email sent',
    color: StatusColor.DONE,
    order: 190,
  },
};
