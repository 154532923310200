import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import { Button, Typography, Alert } from '@passthrough/uikit';
import { Dialog } from 'components/dialog/index';
import * as api from 'services/api';
import * as urls from 'services/urls';
import { getSelectedStaffOrg } from 'services/utils';
import { useHotkeys } from 'react-hotkeys-hook';
import { useRegionalAction } from 'services/regional';
import { SearchResults } from './SearchResults';
import { NavSearchButton } from './NavSearchButton';
import { SearchFooter } from './SearchFooter';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
    overflow: 'scroll',
  },
  searchBar: {
    width: '100%',
  },
  searchBarInputBase: {
    borderRadius: 0,
  },
  notchedOutline: {}, // Used to clear out default border
  focused: {
    '&$focused $notchedOutline': {
      borderRadius: 0,
      borderWidth: 0,
      outline: `1px solid ${theme.palette.neutral.border}`,
    },
  },
  dialogPaper: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(15),
    },
  },
  scrollPaper: {
    alignItems: 'start',
  },
}));

export function UniversalSearchBar({ useMobileUI }) {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState('');
  const [throttledSearchQuery, setThrottledSearchQuery] = useState('');
  const [results, setResults] = useState([]);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  const hasResults = results.length > 0;
  const hotkeysOptions = {
    enabled: searchModalOpen,
  };

  const closeModal = () => {
    setSearchModalOpen(false);
  };

  const handleUpKey = () => {
    if (hasResults) {
      if (selectedItemIndex === null) {
        setSelectedItemIndex(0);
      } else if (selectedItemIndex > 0) {
        setSelectedItemIndex((prevIndex) => prevIndex - 1);
      }
    }
  };

  const handleDownKey = () => {
    if (hasResults) {
      if (selectedItemIndex === null) {
        setSelectedItemIndex(0);
      } else if (selectedItemIndex < results.length - 1) {
        setSelectedItemIndex((prevIndex) => prevIndex + 1);
      }
    }
  };

  const handleEnterKey = () => {
    if (hasResults && selectedItemIndex !== null) {
      const result = results[selectedItemIndex];
      onInvestorClick(result);
    }
  };

  useHotkeys(
    'mod+k',
    () => {
      setSearchModalOpen(true);
    },
    { preventDefault: true }, // Prevent builtin browser mod + k behavior
  );
  useHotkeys('down', handleDownKey, hotkeysOptions);
  useHotkeys('up', handleUpKey, hotkeysOptions);
  useHotkeys('enter,return', handleEnterKey, hotkeysOptions);
  useHotkeys('esc', closeModal, hotkeysOptions);

  const selectedStaffOrg = getSelectedStaffOrg();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setThrottledSearchQuery(searchQuery);
    }, 300);
    return () => clearTimeout(timeout);
  }, [searchQuery]);

  useEffect(() => {
    if (throttledSearchQuery.length < 3) {
      setResults([]);
      setHasSearched(false);
      setSelectedItemIndex(null);
      return;
    }

    api
      .universalSearch({
        searchTerm: throttledSearchQuery,
        organizationId: selectedStaffOrg,
      })
      .then((response) => {
        setResults(response.data);
        setHasSearched(true);
        setSelectedItemIndex(null);
      });
  }, [throttledSearchQuery]);

  const clearSearch = () => {
    setSearchQuery('');
    setThrottledSearchQuery('');
    setResults([]);
    setHasSearched(false);
    setSelectedItemIndex(null);
  };

  const action = useRegionalAction();

  function onInvestorClick(investorResult) {
    const { baseUrl } = investorResult;
    closeModal();
    return action(
      baseUrl,
      urls.reviewUrl({
        fundId: investorResult.fundId,
        closingId: investorResult.closingId,
        lpClosingId: investorResult.lpClosingId,
      }),
    );
  }

  return (
    <>
      <NavSearchButton
        useMobileUI={useMobileUI}
        setSearchModalOpen={setSearchModalOpen}
      />
      <Dialog
        open={searchModalOpen}
        TransitionProps={{
          onExited: () => {
            clearSearch();
          },
        }}
        onClose={() => {
          setSearchModalOpen(false);
        }}
        maxWidth="sm"
        fullWidth
        fullScreen={useMobileUI}
        PaperProps={{
          variant: 'outlined',
        }}
        classes={{
          root: classes.dialogPaper,
          scrollPaper: classes.scrollPaper,
        }}
      >
        <TextField
          variant="outlined"
          className={classes.searchBar}
          value={searchQuery}
          color="black"
          autoFocus
          focused
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'ArrowDown') {
              handleDownKey();
              if (hasResults) {
                // Allow focus on text field while navigating up and down
                e.preventDefault();
              }
            }
            if (e.key === 'ArrowUp') {
              handleUpKey();
              if (hasResults) {
                // Allow focus on text field while navigating up and down
                e.preventDefault();
              }
            }
            if (e.key === 'Enter') {
              handleEnterKey();
              e.preventDefault();
            }
          }}
          placeholder="Find an investor..."
          InputProps={{
            className: classes.searchBarInputBase,
            classes: {
              notchedOutline: classes.notchedOutline,
              focused: classes.focused,
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {hasSearched ? (
                  <Button size="small" variant="text" onClick={clearSearch}>
                    Clear
                  </Button>
                ) : null}
                <Button
                  size="small"
                  variant="icon"
                  onClick={closeModal}
                  aria-label="Close search"
                >
                  <CloseIcon />
                </Button>
              </InputAdornment>
            ),
          }}
        />
        {!hasSearched ? (
          <Alert severity="info" variant="sticky">
            You can search by investor name, collaborator name or email.
          </Alert>
        ) : (
          <div className={classes.content}>
            {hasResults ? <Typography>Investors</Typography> : null}
            <SearchResults
              searchInput={throttledSearchQuery}
              hasSearched={hasSearched}
              results={results}
              clearSearch={clearSearch}
              selectedItemIndex={selectedItemIndex}
              onInvestorClick={onInvestorClick}
            />
          </div>
        )}
        {hasResults && !useMobileUI ? <SearchFooter /> : null}
      </Dialog>
    </>
  );
}
