import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import { Alert, Typography, Button } from '@passthrough/uikit';
import { useWhiteLabelConfig } from 'services/providers/theme';
import { Spinner } from 'components/spinner';
import { ExternalLogo } from 'components/logo';
import { SignInWaitStep } from 'pages/signin_v2/wait';
import * as api from 'services/api';
import { Copyright } from 'pages/lp_doc/copyright_v2';
import { TypographyWithLineBreaks } from 'components/typography_with_line_breaks';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  logoContainer: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
  },
  fundLogo: {
    alignSelf: 'center',
    maxWidth: '12vw',
    minWidth: '140px',
    objectFit: 'contain',
    marginBottom: theme.spacing(5.5),
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(4),
    alignSelf: 'stretch',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  stepContainer: {
    overflow: 'hidden',
    maxWidth: '460px',
    display: 'flex',
    flexDirection: 'column',
  },
  step: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  contentHeader: {
    display: 'flex',
    marginBottom: theme.spacing(4),
    justifyContent: 'center',
  },
  hiddenStep: {
    height: 0,
  },
  error: {
    marginTop: theme.spacing(1),
  },
  next: {
    marginTop: theme.spacing(5),
  },
  helperText: {
    marginTop: '-4px',
  },
  inviteHeader: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
}));

const STEP_INTRO = 1;
const STEP_FORM = 2;
const STEP_WAIT = 3;

function IntroStep({ name, text, setStep }) {
  const classes = useStyles();
  const { productName } = useWhiteLabelConfig();

  const introText =
    text ||
    `${name} is using ${productName} to manage their ` +
      'subscription documents and investor questionnaire. ' +
      "You'll be asked a series of questions to collect investor " +
      'information and determine eligibility.';

  return (
    <div>
      <div className={classes.contentHeader}>
        <Typography variant="section-heading">
          You're invited to invest.
        </Typography>
      </div>

      <TypographyWithLineBreaks>{introText}</TypographyWithLineBreaks>

      <div className={classes.next}>
        <Button
          variant="primary"
          fullWidth
          onClick={() => {
            setStep(STEP_FORM);
          }}
          htmlProps={{
            style: {
              maxWidth: '100%',
            },
          }}
        >
          Get started
        </Button>
      </div>
    </div>
  );
}

function FormStep({ slug, setStep, email, setEmail }) {
  const classes = useStyles();
  const history = useHistory();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [formError, setFormError] = useState('');
  const [loading, setLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);
    setFormError('');

    api
      .externalSignup({ slug, name, email })
      .then((response) => {
        // setStep(STEP_WAIT);
        // This sends them directly to the sign in page with their email
        history.push(response.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          if (Array.isArray(error.response.data)) {
            setFormError(error.response.data[0]);
          }
          if (error.response.data.signinPath) {
            history.push(error.response.data.signinPath);
          }
          if (error.response.data.detail) {
            setFormError(error.response.data.detail);
          }
          setNameError(error.response.data.name);
          setEmailError(error.response.data.email);
          setLoading(false);
        }
      });
  }

  return (
    <>
      <div>
        <Typography variant="section-heading">Who is investing?</Typography>

        <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
          {formError ? (
            <div className={classes.error}>
              <Alert severity="error">{formError}</Alert>
            </div>
          ) : null}

          <TextField
            InputProps={{
              className: classes.input,
            }}
            variant="outlined"
            margin="normal"
            fullWidth
            id="name"
            label="Investor name"
            name="name"
            autoComplete="name"
            autoFocus
            error={!!nameError}
            helperText={nameError}
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={loading}
          />
          <FormHelperText className={classes.helperText}>
            If the investor is an entity, enter its name.
          </FormHelperText>
          <TextField
            InputProps={{
              className: classes.input,
            }}
            variant="outlined"
            margin="normal"
            fullWidth
            type="email"
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            error={!!emailError}
            helperText={emailError}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />
          <FormHelperText className={classes.helperText}>
            The email of the person who will complete the questionnaire.
          </FormHelperText>

          <div className={classes.next}>
            <Button
              type="submit"
              variant="primary"
              fullWidth
              loading={loading}
              onClick={() => {
                setStep(STEP_FORM);
              }}
              htmlProps={{
                style: {
                  maxWidth: '100%',
                },
              }}
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export function ExternalSignup({ setSigninError }) {
  const { slug } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [step, setStep] = useState(STEP_INTRO);
  const [closing, setClosing] = useState(null);

  const [email, setEmail] = useState('');

  function getClosingData() {
    api
      .externalSignupGet({ slug })
      .then((response) => {
        setClosing(response.data);
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          setSigninError(
            'The signup link is inactive. You may sign in, or contact the sender of the link.',
          );
          history.push('/signin');
        }
      });
  }

  useEffect(getClosingData, [slug]);

  if (closing === null) {
    return <Spinner fullScreen />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.logoContainer}>
        <ExternalLogo />
      </div>
      <div className={classes.container}>
        <div className={classes.stepContainer}>
          {closing.logo ? (
            <img src={closing.logo} className={classes.fundLogo} alt="" />
          ) : null}
          <Slide
            direction="right"
            in={step === STEP_INTRO}
            appear={false}
            mountOnEnter
            unmountOnExit
          >
            <div
              className={clsx(classes.step, {
                [classes.hiddenStep]: step !== STEP_INTRO,
              })}
            >
              <IntroStep
                name={closing.name}
                text={closing.text}
                setStep={setStep}
              />
            </div>
          </Slide>
          <Slide
            direction="left"
            in={step === STEP_FORM}
            mountOnEnter
            unmountOnExit
          >
            <div
              className={clsx(classes.step, {
                [classes.hiddenStep]: step !== STEP_FORM,
              })}
            >
              <FormStep
                slug={slug}
                setStep={setStep}
                email={email}
                setEmail={setEmail}
              />
            </div>
          </Slide>
          <Fade in={step === STEP_WAIT}>
            <div
              className={clsx(classes.step, {
                [classes.hiddenStep]: step !== STEP_WAIT,
              })}
            >
              <SignInWaitStep email={email} />
            </div>
          </Fade>
        </div>
      </div>
      <Copyright />
    </div>
  );
}
