import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, Link } from 'react-router-dom';
import { closingUrl } from 'services/urls';
import { Typography, SpotIcon, Button, Chip } from '@passthrough/uikit';
import format from 'date-fns/format';
import Tooltip from '@material-ui/core/Tooltip';

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import FingerprintOutlinedIcon from '@material-ui/icons/FingerprintOutlined';
import LinkIcon from '@material-ui/icons/LinkOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import FolderMultipleOutlineIcon from 'mdi-material-ui/FolderMultipleOutline';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { ProgressInfo } from 'components/progress';
import {
  DILIGENCE_REVIEWED,
  FULLY_EXECUTED,
  PENDING,
  PENDING_COMMITMENT,
} from 'components/progress/progress_info';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  row: {
    padding: theme.spacing(2),
    paddingRight: '56px',
    [theme.breakpoints.up('sm')]: {
      paddingRight: '92px',
    },
    overflow: 'hidden',
    '&:hover,&:focus-visible': {
      backgroundColor: theme.palette.background.default,
      '& + $arrow': {
        '& svg': {
          color: theme.palette.primary.main,
        },
        transform: 'translate(8px, -50%)',
      },
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: theme.spacing(1),
      '& > :last-child': {
        marginLeft: '-56px',
      },
    },
  },

  arrow: {
    color: theme.palette.text.secondary,
    transition: theme.transitions.create(['transform', 'color']),
    [theme.breakpoints.up('sm')]: {
      right: theme.spacing(3),
    },
  },

  icon: {
    '& > *:last-child:not(:first-child)': {
      marginTop: '-10px',
    },
    '& > *:first-child': {
      zIndex: 1,
    },
  },

  metadata: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },

  tooltips: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    columnGap: theme.spacing(1),
    color: theme.palette.text.secondary,
    '& > span': {
      display: 'flex',
      flexDirection: 'row',
      gap: '4px',
      alignItems: 'center',
    },
  },

  closingInfo: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  divider: {
    'li:last-child > &': {
      borderBottom: 'none',
    },
  },
}));

function ClosingIcon({ closing }) {
  const {
    diligenceEnabled,
    subdocsEnabled,
    diligenceClosingName,
    subdocClosingNames = [],
  } = closing;
  const classes = useStyles();
  return (
    <div className={classes.icon}>
      {subdocsEnabled && (
        <SpotIcon size="medium">
          <DescriptionOutlinedIcon />
        </SpotIcon>
      )}
      {diligenceEnabled && (
        <SpotIcon size="medium" variant="warning">
          <FingerprintOutlinedIcon />
        </SpotIcon>
      )}
      {(diligenceClosingName || subdocClosingNames.length > 0) && (
        <SpotIcon size="medium" variant="neutral">
          <LinkIcon />
        </SpotIcon>
      )}
    </div>
  );
}

function ClosingInfo({ closing }) {
  const {
    diligenceEnabled,
    subdocsEnabled,
    createdAt,
    name: closingName,
    closingDate,
    diligenceClosingName,
    subdocClosingNames = [],
  } = closing;
  const classes = useStyles();
  const closingTypes = [];
  if (subdocsEnabled) {
    closingTypes.push('Questionnaire');
  }

  if (diligenceEnabled) {
    closingTypes.push('Diligence');
  }

  const joinedSubdocNames = subdocClosingNames.reduce((acc, name, i) => {
    const nameElement =
      name.length > 20 ? (
        <Tooltip
          title={
            <Typography variant="label" color="inherit">
              {name}
            </Typography>
          }
        >
          <span>{`${name.slice(0, 20)}...`}</span>
        </Tooltip>
      ) : (
        name
      );
    if (i === 0) {
      return [nameElement];
    }
    return [...acc, ', ', nameElement];
  }, []);

  return (
    <div className={classes.closingInfo}>
      <div className={classes.metadata}>
        <div>
          <Typography
            variant="card-heading"
            size="large"
            id={`header-${closing.id}`}
          >
            {closingName}
          </Typography>
          <Typography variant="label" size="small">
            Created {format(new Date(createdAt), 'dd LLL yyyy')}
          </Typography>
        </div>
        <div className={classes.tooltips}>
          <Tooltip
            title={
              <Typography variant="label" color="inherit">
                Closing type
              </Typography>
            }
          >
            <span>
              <FolderMultipleOutlineIcon fontSize="inherit" />
              <Typography variant="label">{closingTypes.join(', ')}</Typography>
            </span>
          </Tooltip>
          {closingDate || subdocsEnabled ? (
            <Tooltip
              title={
                <Typography variant="label" color="inherit">
                  Closing date
                </Typography>
              }
            >
              <span>
                <CalendarTodayOutlinedIcon fontSize="inherit" />
                <Typography variant="label" noWrap>
                  {closingDate
                    ? format(new Date(closingDate), 'dd LLL yyyy')
                    : 'No closing date'}
                </Typography>
              </span>
            </Tooltip>
          ) : null}
          {closing.diligenceEnabled && closing.jurisdiction ? (
            <Tooltip
              title={
                <Typography variant="label" color="inherit">
                  Jurisdiction
                </Typography>
              }
            >
              <span>
                <LocationOnIcon fontSize="inherit" />
                <Typography variant="label" noWrap>
                  {closing.jurisdiction}
                </Typography>
              </span>
            </Tooltip>
          ) : null}

          {diligenceClosingName ? (
            <span>
              <LinkIcon fontSize="inherit" />
              <Typography variant="label" noWrap>
                {diligenceClosingName}
              </Typography>
            </span>
          ) : null}
          {joinedSubdocNames.length ? (
            <span>
              <Tooltip
                title={
                  <Typography variant="label" color="inherit">
                    Linked closings
                  </Typography>
                }
              >
                <LinkIcon fontSize="inherit" />
              </Tooltip>
              <Typography variant="label" noWrap>
                {joinedSubdocNames}
              </Typography>
            </span>
          ) : null}
        </div>
        {closing.readOnly ? (
          <Chip
            className={classes.chip}
            icon={<WarningIcon />}
            label="Read-only"
            variant="warning"
            size="small"
          />
        ) : null}
      </div>
    </div>
  );
}

function Progress({ closing, currency }) {
  if (!closing.subdocsEnabled) {
    return (
      <ProgressInfo
        pendingInvestorCount={closing.numPending}
        reviewedCount={closing.numDiligenceApproved}
        show={[PENDING, DILIGENCE_REVIEWED]}
        hideProgressBar
      />
    );
  }
  return (
    <ProgressInfo
      fullyExecuted={closing.commitmentFullyExecuted}
      fullyExecutedInvestorCount={closing.numFullyExecuted}
      pending={closing.commitmentPending}
      pendingInvestorCount={closing.numPending}
      show={[PENDING_COMMITMENT, FULLY_EXECUTED]}
      currency={currency}
    />
  );
}

export function ClosingCard({ closing, currency }) {
  const { fundId } = useParams();
  const classes = useStyles();
  const url = closingUrl({ fundId, closingId: closing.id });

  return (
    <ListItem
      data-test="closing"
      button
      component={Link}
      to={url}
      aria-labelledby={`header-${closing.id}`}
      classes={{
        root: classes.row,
        divider: classes.divider,
      }}
      alignItems="flex-start"
      divider
      disableRipple
    >
      <ListItemAvatar>
        <ClosingIcon closing={closing} />
      </ListItemAvatar>

      <ListItemText disableTypography className={classes.content}>
        <ClosingInfo closing={closing} />
        <Progress closing={closing} currency={currency} />
      </ListItemText>

      <ListItemSecondaryAction className={classes.arrow}>
        <Button
          variant="icon"
          size="medium"
          href={url}
          aria-label={`Go to ${closing.name}`}
        >
          <ArrowForwardIcon />
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
