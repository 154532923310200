import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

import { Typography, Modal } from '@passthrough/uikit';
import { useToast } from 'services/toast';
import { isValidPageRange } from 'services/utils';

export const BulkPageShiftModal = ({
  open,
  isSaving,
  selectedQuestions,
  onClose,
  onSave,
}) => {
  const [pageNumberShift, setPageNumberShift] = useState(0);
  const [pageRange, setPageRange] = useState('');
  const { toast } = useToast();

  const questionIdsThatCannotShift = selectedQuestions.reduce((priorIds, q) => {
    const questionContainsInvalidBoxShift = (q.hellosignBoxes || []).some(
      (box) => box.page + pageNumberShift <= 0,
    );

    if (questionContainsInvalidBoxShift) {
      priorIds.push(q.id);
    }

    return priorIds;
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      primaryButtonText="Save"
      primaryButtonLoading={isSaving}
      primaryButtonDisabled={
        !pageNumberShift ||
        questionIdsThatCannotShift.length > 0 ||
        (pageRange && !isValidPageRange(pageRange))
      }
      headerLabel="Bulk page shift"
      onExited={() => {
        setPageNumberShift(0);
        setPageRange('');
      }}
      onSubmit={() => {
        onSave(pageNumberShift, pageRange);
        onClose();
        toast(
          `${selectedQuestions.reduce(
            (total, question) =>
              total +
              (question.hellosignBoxes || []).length +
              (question.links || []).length,
            0,
          )} output boxes and links moved.`,
        );
      }}
      showCancelButton
    >
      <Typography>
        Each output box and link of the selected questions will shift its page
        number by the specified amount.
      </Typography>

      <TextField
        value={pageNumberShift}
        onChange={(e) => {
          setPageNumberShift(Number(e.target.value));
        }}
        variant="outlined"
        required
        type="number"
        label="Page shift"
      />

      <TextField
        value={pageRange}
        onChange={(e) => setPageRange(e.target.value)}
        placeholder="1-5,8,11-13"
        variant="outlined"
        type="text"
        label="Pages to shift (optional)"
        helperText="Leave blank to shift all pages"
      />

      {questionIdsThatCannotShift.length > 0 ? (
        <Typography>
          The following questions contain output boxes or links that cannot be
          decremented by the specified amount:{' '}
          {questionIdsThatCannotShift.join(', ')}
        </Typography>
      ) : null}
    </Modal>
  );
};
