import React, { useState, useEffect } from 'react';
import { useHistory, Prompt } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { GPSelectionInput } from 'components/gp_selection_input/index';
import { Spinner } from 'components/spinner';
import * as constants from 'components/gp_selection_input/constants';
import { Modal, Alert, Typography, Button } from '@passthrough/uikit';
import { useToast } from 'services/toast';
import * as api from 'services/api';
import * as urls from 'services/urls';
import { useFund } from 'services/providers/fund';
import { formatDate, getDefaultCountersignerLabel } from 'services/utils';

import { ClosingDateField } from 'components/closing_date_field';
import { useSupport } from 'components/support';
import { JurisdictionSelect } from 'components/jurisdiction_select';
import { DiscardAlert } from '../discard';

const QUESTIONNAIRE_TYPE = 'Questionnaire';
const DILIGENCE_TYPE = 'Diligence';

const CLOSING_TYPES = [QUESTIONNAIRE_TYPE, DILIGENCE_TYPE];

export function NewClosingDialog({
  fundId,
  managedDiligenceEnabled,
  open,
  onClose,
  members,
  isLoadingMembers,
}) {
  const [fund, fundLoading] = useFund();
  const orgDiligenceEnabled = Boolean(fund?.diligenceEnabled);
  const history = useHistory();
  const [subdocs, setSubdocs] = useState([]);
  const [closingTypes, setClosingTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const [omitClosingDate, setOmitClosingDate] = useState(false);

  const { successToast } = useToast();
  const { showSupport } = useSupport();

  const [discardOpen, setDiscardOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const changed = Object.keys(form).length > 0 || omitClosingDate;

  function handleClose() {
    if (changed) {
      setDiscardOpen(true);
    } else {
      onClose();
    }
  }

  const possibleDiligenceReviewers = managedDiligenceEnabled
    ? members?.filter((member) => member.canReviewManagedDiligence)
    : members;

  const changeClosingTypes = (newTypes) => {
    setErrors({ ...errors, closingTypes: null });
    setClosingTypes(newTypes);
  };

  const isQuestionnaireType =
    closingTypes.includes(QUESTIONNAIRE_TYPE) || !orgDiligenceEnabled;
  const isDiligenceType = closingTypes.includes(DILIGENCE_TYPE);
  const showClosingTypes = orgDiligenceEnabled;

  const closingDateToPass =
    omitClosingDate || !form.closingDate ? null : form.closingDate;
  const missingClosingDate = !omitClosingDate && !form.closingDate;

  // Only provide a default subdoc if there is exactly one
  const defaultSubDocId = subdocs.length === 1 ? subdocs[0].id : null;
  const subscriptionDocumentIdToPass = isQuestionnaireType
    ? form.subscriptionDocumentId || defaultSubDocId
    : null;

  const subdoc = subdocs.find((s) => s.id === subscriptionDocumentIdToPass);
  const numberOfCountersigners = subdoc?.numberOfCountersigners || 0;
  const countersigner1Label = getDefaultCountersignerLabel(
    subdoc?.countersigner1DisplayName || 'Countersigner',
  );
  const countersigner2Label = getDefaultCountersignerLabel(
    subdoc?.countersigner2DisplayName || 'Second countersigner',
  );
  const countersigner3Label = getDefaultCountersignerLabel(
    subdoc?.countersigner3DisplayName || 'Third countersigner',
  );

  const noName = !form.name;
  const noClosingTypesSelected =
    orgDiligenceEnabled && closingTypes.length === 0;
  const noSubscriptionDocumentSelected =
    isQuestionnaireType && !subscriptionDocumentIdToPass;
  const noDiligenceJurisdiction =
    isDiligenceType && !form.diligenceJurisdiction;

  const missingRequiredFields =
    noName ||
    missingClosingDate ||
    noClosingTypesSelected ||
    noSubscriptionDocumentSelected ||
    noDiligenceJurisdiction;

  function fetchSubdocs() {
    if (!fundLoading) {
      api.getSubscriptionDocumentList({ fundId }).then((response) => {
        setSubdocs(response.data);
      });
    }
  }

  useEffect(fetchSubdocs, [fundId, fundLoading]);

  useEffect(() => {
    const cleanup = () => {
      setForm({});
      setErrors({});
      setClosingTypes([]);
      setOmitClosingDate(false);
      setSubmitted(false);
    };
    return cleanup;
  }, [open]);

  function handleSubmit() {
    let formattedDate;
    try {
      formattedDate = formatDate(closingDateToPass);
    } catch (error) {
      formattedDate = '';
    }

    const submitForm = {
      ...form,
      closingDate: formattedDate,
      subscriptionDocumentId: subscriptionDocumentIdToPass,
    };

    let { subscriptionDocumentId } = submitForm;
    let diligenceEnabled = isDiligenceType;

    if (showClosingTypes) {
      if (!closingTypes.length) {
        setErrors({ ...errors, closingTypes: 'Closing type is required' });
        return;
      }

      if (!isDiligenceType) {
        diligenceEnabled = false;
      }

      if (!isQuestionnaireType) {
        subscriptionDocumentId = null;
      }

      submitForm.closingTypes = closingTypes;
    }

    submitForm.subscriptionDocumentId = subscriptionDocumentId;
    submitForm.diligenceEnabled = diligenceEnabled;

    setSubmitted(true);
    setLoading(true);
    api
      .createClosing({ fundId, ...submitForm })
      .then((response) => {
        successToast(`Created new closing: ${submitForm.name}`);
        history.push(urls.closingUrl({ fundId, closingId: response.data.id }));
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setErrors(error.response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function renderContent() {
    return (
      <>
        <Prompt
          message="Your changes have not been saved. Are you sure you want to leave?"
          when={!submitted && changed}
        />
        {errors.diligenceEnabled ? (
          <Alert
            severity="error"
            action={
              <Button
                variant="text"
                external
                onClick={showSupport}
                size="small"
              >
                Contact support
              </Button>
            }
          >
            Diligence closings are not enabled for this fund.
          </Alert>
        ) : null}
        {errors.nonFieldErrors ? (
          <Alert severity="error">{errors.nonFieldErrors}</Alert>
        ) : null}
        {orgDiligenceEnabled ? (
          <FormControl
            variant="outlined"
            fullWidth
            required
            error={!!errors.closingTypes}
          >
            <InputLabel id="closingType">Closing type</InputLabel>
            <Select
              autoFocus
              label="Closing type"
              id="closing-type"
              labelId="closing-type-label"
              variant="outlined"
              value={closingTypes}
              renderValue={(selected) => selected.join(', ')}
              onChange={(e) => {
                changeClosingTypes(e.target.value);
              }}
              multiple
              error={!!errors.closingTypes}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {CLOSING_TYPES.map((closingType) => (
                <MenuItem
                  key={closingType}
                  value={closingType}
                  data-test={`${closingType}`}
                >
                  <Checkbox checked={closingTypes?.indexOf(closingType) > -1} />
                  <ListItemText primary={closingType} />
                </MenuItem>
              ))}
            </Select>
            {errors.closingTypes ? (
              <FormHelperText>{errors.closingTypes}</FormHelperText>
            ) : null}
            <FormHelperText>
              Closing type cannot be changed later.
            </FormHelperText>
          </FormControl>
        ) : null}
        <TextField
          autoFocus
          required
          id="name"
          variant="outlined"
          label="Name"
          type="text"
          value={form.name}
          onChange={(e) => setForm({ ...form, name: e.target.value })}
          error={!!errors.name}
          helperText={errors.name}
          fullWidth
        />

        <ClosingDateField
          closingDate={closingDateToPass}
          setClosingDate={(value) => {
            setForm({ ...form, closingDate: value });
          }}
          closingDateError={errors.closingDate}
          omitClosingDate={omitClosingDate}
          setOmitClosingDate={setOmitClosingDate}
        />

        {subdocs.length > 0 && isQuestionnaireType ? (
          <>
            {showClosingTypes ? (
              <Typography variant="card-heading">
                Questionnaire settings
              </Typography>
            ) : null}
            <FormControl
              variant="outlined"
              fullWidth
              required
              error={!!errors.subscriptionDocumentId}
            >
              <InputLabel id="subdoc">Subscription document</InputLabel>
              <Select
                label="Subscription document"
                labelId="subdoc"
                variant="outlined"
                helperText={errors.subscriptionDocumentId}
                value={subscriptionDocumentIdToPass}
                onChange={(e) => {
                  setForm({ ...form, subscriptionDocumentId: e.target.value });
                }}
              >
                {subdocs.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              {errors.subscriptionDocumentId ? (
                <FormHelperText>{errors.subscriptionDocumentId}</FormHelperText>
              ) : null}
            </FormControl>
            {numberOfCountersigners > 0 ? (
              <>
                <GPSelectionInput
                  label={countersigner1Label}
                  errorMsg={errors.defaultCountersignerId}
                  value={form.defaultCountersignerId || ''}
                  setValue={(id) =>
                    setForm({ ...form, defaultCountersignerId: id })
                  }
                  roleLabelNum={constants.DEFAULT_COUNTERSIGNER_1_LABEL_NUM}
                  members={members}
                  fundId={fundId}
                  includeBlankOption
                />
                {numberOfCountersigners > 1 ? (
                  <GPSelectionInput
                    label={countersigner2Label}
                    errorMsg={errors.defaultCountersigner2Id}
                    value={form.defaultCountersigner2Id}
                    setValue={(id) =>
                      setForm({ ...form, defaultCountersigner2Id: id })
                    }
                    roleLabelNum={constants.DEFAULT_COUNTERSIGNER_2_LABEL_NUM}
                    members={members}
                    fundId={fundId}
                    includeBlankOption
                  />
                ) : null}
                {numberOfCountersigners > 2 ? (
                  <GPSelectionInput
                    label={countersigner3Label}
                    errorMsg={errors.defaultCountersigner3Id}
                    value={form.defaultCountersigner3Id}
                    setValue={(id) =>
                      setForm({ ...form, defaultCountersigner3Id: id })
                    }
                    roleLabelNum={constants.DEFAULT_COUNTERSIGNER_3_LABEL_NUM}
                    members={members}
                    fundId={fundId}
                    includeBlankOption
                  />
                ) : null}
              </>
            ) : null}
          </>
        ) : null}

        {orgDiligenceEnabled && isDiligenceType ? (
          <>
            {showClosingTypes ? (
              <Typography variant="card-heading">Diligence settings</Typography>
            ) : null}
            <JurisdictionSelect
              jurisdiction={form.diligenceJurisdiction}
              jurisdictionError={errors.diligenceJurisdiction}
              onChange={(e) => {
                setForm((f) => ({
                  ...f,
                  diligenceJurisdiction: e.target.value,
                }));
              }}
            />
            <GPSelectionInput
              label="Diligence reviewer"
              errorMsg={errors.defaultDiligenceApproverId}
              value={form.defaultDiligenceApproverId}
              setValue={(id) => {
                setForm((f) => ({
                  ...f,
                  defaultDiligenceApproverId: id,
                }));
              }}
              roleLabelNum={constants.DEFAULT_DILIGENCE_APPROVER_LABEL_NUM}
              members={possibleDiligenceReviewers}
              fundId={fundId}
              includeBlankOption
              required
            />
          </>
        ) : null}
      </>
    );
  }

  return (
    <>
      <DiscardAlert
        open={discardOpen}
        onDiscard={() => {
          setDiscardOpen(false);
          onClose();
        }}
        onClose={() => setDiscardOpen(false)}
      />

      <Modal
        open={open}
        onClose={handleClose}
        headerLabel="Create closing"
        size="sm"
        primaryButtonText="Create"
        onSubmit={handleSubmit}
        showCancelButton
        primaryButtonLoading={loading}
        primaryButtonDisabled={missingRequiredFields}
        primaryButtonProps={{
          htmlProps: {
            'data-test': 'create',
          },
        }}
      >
        {isLoadingMembers ? <Spinner fullScreen /> : renderContent()}
      </Modal>
    </>
  );
}
