import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';

import { Alert, Button, Modal, Typography } from '@passthrough/uikit';

import { ClosingDateField } from 'components/closing_date_field';
import * as constants from 'components/gp_selection_input/constants';
import { GPSelectionInput } from 'components/gp_selection_input/index';
import { Spinner } from 'components/spinner';
import { useSupport } from 'components/support';
import { Prompt, useHistory } from 'react-router-dom';

import * as api from 'services/api';
import { useFund } from 'services/providers/fund';
import { useToast } from 'services/toast';
import * as urls from 'services/urls';
import { formatDate, getDefaultCountersignerLabel } from 'services/utils';

import { CLOSING_TYPES } from '../../constants';
import { DiscardAlert } from '../discard';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginLeft: 0,
  },
  jurisdictionIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  jurisdiction: {
    paddingLeft: theme.spacing(4),
  },
}));

export function NewClosingDialog({
  fundId,
  open,
  onClose,
  members,
  isLoadingMembers,
}) {
  const classes = useStyles();
  const [fund, fundLoading] = useFund();
  const orgDiligenceEnabled = Boolean(fund?.diligenceEnabled);
  const history = useHistory();
  const [subdocs, setSubdocs] = useState([]);
  const [diligenceClosings, setDiligenceClosings] = useState([]);
  const [loading, setLoading] = useState(false);

  const defaultSubDocId = subdocs.length === 1 ? subdocs[0].id : null;
  const [form, setForm] = useState({
    subscriptionDocumentId: defaultSubDocId,
  });
  const [errors, setErrors] = useState({});

  const [omitClosingDate, setOmitClosingDate] = useState(false);

  const { successToast } = useToast();
  const { showSupport } = useSupport();

  const [discardOpen, setDiscardOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const changed =
    Object.keys(form).length > 0 || omitClosingDate || form.diligenceEnabled;

  function handleClose() {
    if (changed) {
      setDiscardOpen(true);
    } else {
      onClose();
    }
  }

  const closingDateToPass =
    omitClosingDate || !form.closingDate ? null : form.closingDate;
  const missingClosingDate = !omitClosingDate && !form.closingDate;

  const subdoc = subdocs.find((s) => s.id === form.subscriptionDocumentId);
  const numberOfCountersigners = subdoc?.numberOfCountersigners || 0;
  const countersigner1Label = getDefaultCountersignerLabel(
    subdoc?.countersigner1DisplayName || 'Countersigner',
  );
  const countersigner2Label = getDefaultCountersignerLabel(
    subdoc?.countersigner2DisplayName || 'Second countersigner',
  );
  const countersigner3Label = getDefaultCountersignerLabel(
    subdoc?.countersigner3DisplayName || 'Third countersigner',
  );

  const noName = !form.name;
  const noSubscriptionDocumentSelected = !form.subscriptionDocumentId;
  const noDiligenceClosingId =
    form.diligenceEnabled && !form.diligenceClosingId;

  const missingRequiredFields =
    noName ||
    missingClosingDate ||
    noSubscriptionDocumentSelected ||
    noDiligenceClosingId;

  function fetchSubdocs() {
    if (!fundLoading) {
      api.getSubscriptionDocumentList({ fundId }).then((response) => {
        setSubdocs(response.data);
      });
    }
  }
  useEffect(fetchSubdocs, [fundId, fundLoading]);

  function getDiligenceClosings() {
    if (!orgDiligenceEnabled || !open || fundLoading) {
      setDiligenceClosings([]);
      return;
    }
    api
      .closings({ fundId, closingType: CLOSING_TYPES.diligence })
      .then((response) => {
        setDiligenceClosings(response.data);
      });
  }
  useEffect(getDiligenceClosings, [fundId, orgDiligenceEnabled, open]);

  useEffect(() => {
    const cleanup = () => {
      setForm({});
      setErrors({});
      setOmitClosingDate(false);
      setSubmitted(false);
    };
    return cleanup;
  }, [open]);

  useEffect(() => {
    if (!form.diligenceEnabled) {
      setForm((f) => ({
        ...f,
        defaultDiligenceApproverId: null,
        diligenceClosingId: null,
      }));
    }
  }, [form.diligenceEnabled]);

  function handleSubmit() {
    let formattedDate;
    try {
      formattedDate = formatDate(closingDateToPass);
    } catch (error) {
      formattedDate = '';
    }

    const submitForm = {
      ...form,
      closingDate: formattedDate,
    };

    setSubmitted(true);
    setLoading(true);
    api
      .createClosing({ fundId, ...submitForm })
      .then((response) => {
        successToast(`Created new closing: ${submitForm.name}`);
        history.push(urls.closingUrl({ fundId, closingId: response.data.id }));
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setErrors(error.response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function renderContent() {
    return (
      <>
        <Prompt
          message="Your changes have not been saved. Are you sure you want to leave?"
          when={!submitted && changed}
        />
        {errors.diligenceClosingId ? (
          <Alert
            severity="error"
            action={
              <Button
                variant="text"
                external
                onClick={showSupport}
                size="small"
              >
                Contact support
              </Button>
            }
          >
            Diligence closings are not enabled for this fund.
          </Alert>
        ) : null}
        {errors.nonFieldErrors ? (
          <Alert severity="error">{errors.nonFieldErrors}</Alert>
        ) : null}
        <TextField
          autoFocus
          required
          id="name"
          variant="outlined"
          label="Name"
          type="text"
          value={form.name}
          onChange={(e) => setForm({ ...form, name: e.target.value })}
          error={!!errors.name}
          helperText={errors.name}
          fullWidth
        />
        {subdocs.length > 0 ? (
          <FormControl
            variant="outlined"
            fullWidth
            required
            error={!!errors.subscriptionDocumentId}
          >
            <InputLabel id="subdoc">Subscription document</InputLabel>
            <Select
              label="Subscription document"
              labelId="subdoc"
              variant="outlined"
              helperText={errors.subscriptionDocumentId}
              value={form.subscriptionDocumentId}
              onChange={(e) => {
                setForm({ ...form, subscriptionDocumentId: e.target.value });
              }}
            >
              {subdocs.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            {errors.subscriptionDocumentId ? (
              <FormHelperText>{errors.subscriptionDocumentId}</FormHelperText>
            ) : null}
          </FormControl>
        ) : null}

        <ClosingDateField
          closingDate={closingDateToPass}
          setClosingDate={(value) => {
            setForm({ ...form, closingDate: value });
          }}
          closingDateError={errors.closingDate}
          omitClosingDate={omitClosingDate}
          setOmitClosingDate={setOmitClosingDate}
        />

        {numberOfCountersigners > 0 ? (
          <>
            <GPSelectionInput
              label={countersigner1Label}
              errorMsg={errors.defaultCountersignerId}
              value={form.defaultCountersignerId || ''}
              setValue={(id) =>
                setForm({ ...form, defaultCountersignerId: id })
              }
              roleLabelNum={constants.DEFAULT_COUNTERSIGNER_1_LABEL_NUM}
              members={members}
              fundId={fundId}
              includeBlankOption
            />
            {numberOfCountersigners > 1 ? (
              <GPSelectionInput
                label={countersigner2Label}
                errorMsg={errors.defaultCountersigner2Id}
                value={form.defaultCountersigner2Id}
                setValue={(id) =>
                  setForm({ ...form, defaultCountersigner2Id: id })
                }
                roleLabelNum={constants.DEFAULT_COUNTERSIGNER_2_LABEL_NUM}
                members={members}
                fundId={fundId}
                includeBlankOption
              />
            ) : null}
            {numberOfCountersigners > 2 ? (
              <GPSelectionInput
                label={countersigner3Label}
                errorMsg={errors.defaultCountersigner3Id}
                value={form.defaultCountersigner3Id}
                setValue={(id) =>
                  setForm({ ...form, defaultCountersigner3Id: id })
                }
                roleLabelNum={constants.DEFAULT_COUNTERSIGNER_3_LABEL_NUM}
                members={members}
                fundId={fundId}
                includeBlankOption
              />
            ) : null}
          </>
        ) : null}

        {diligenceClosings.length > 0 && (
          <FormControlLabel
            classes={{ root: classes.checkbox }}
            control={
              <Checkbox
                checked={form.diligenceEnabled}
                onChange={(e) =>
                  setForm({ ...form, diligenceEnabled: e.target.checked })
                }
                data-test="enable-diligence"
              />
            }
            label={
              <>
                <Typography variant="body" size="medium">
                  Require investors to complete Diligence
                </Typography>
                <Typography variant="label" size="small" color="text.secondary">
                  Investor questionnaire will include Diligence requirements for
                  the linked jurisdiction.
                </Typography>
              </>
            }
          />
        )}
        {form.diligenceEnabled ? (
          <>
            <FormControl
              variant="outlined"
              fullWidth
              required
              error={!!errors.diligenceClosingId}
            >
              <InputLabel id="diligence_jurisdiction">Jurisdiction</InputLabel>

              <Select
                label="Jurisdiction"
                labelId="diligenceClosingId"
                data-test="diligence-jurisdiction-select"
                variant="outlined"
                helperText={errors.diligenceClosingId}
                value={form.diligenceClosingId}
                onChange={(e) =>
                  setForm({ ...form, diligenceClosingId: e.target.value })
                }
                renderValue={(selected) => {
                  const selectedJurisdiction = diligenceClosings.find(
                    (closing) => closing.id === selected,
                  );
                  return (
                    <Grid container alignItems="center">
                      <Grid item>
                        <LocationOnIcon className={classes.jurisdictionIcon} />
                      </Grid>
                      <Grid item xs className={classes.jurisdiction}>
                        {selectedJurisdiction?.jurisdiction}
                      </Grid>
                    </Grid>
                  );
                }}
              >
                {diligenceClosings.map(({ id, name, jurisdiction }) => (
                  <MenuItem key={id} value={id} data-test={id}>
                    <Grid
                      container
                      alignItems="center"
                      key={id}
                      data-test="address-option"
                    >
                      <Grid item>
                        <LocationOnIcon className={classes.jurisdictionIcon} />
                      </Grid>
                      <Grid item xs className={classes.jurisdiction}>
                        {jurisdiction}
                        <Typography
                          variant="body"
                          size="small"
                          color="text.secondary"
                        >
                          {name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                ))}
              </Select>

              {errors.diligenceClosingId ? (
                <FormHelperText>{errors.diligenceClosingId}</FormHelperText>
              ) : null}
            </FormControl>
          </>
        ) : null}
      </>
    );
  }

  return (
    <>
      <DiscardAlert
        open={discardOpen}
        onDiscard={() => {
          setDiscardOpen(false);
          onClose();
        }}
        onClose={() => setDiscardOpen(false)}
      />

      <Modal
        open={open}
        onClose={handleClose}
        headerLabel="Create closing"
        size="sm"
        primaryButtonText="Create"
        onSubmit={handleSubmit}
        showCancelButton
        primaryButtonLoading={loading}
        primaryButtonDisabled={missingRequiredFields}
        primaryButtonProps={{
          htmlProps: {
            'data-test': 'create',
          },
        }}
      >
        {isLoadingMembers ? <Spinner fullScreen /> : renderContent()}
      </Modal>
    </>
  );
}
