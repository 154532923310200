import React, { useState, useEffect, useRef } from 'react';
import { format, parse } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import SignatureFreehand from 'mdi-material-ui/SignatureFreehand';
import EmailOffOutline from 'mdi-material-ui/EmailOffOutline';

import {
  Button,
  Chip,
  Menu,
  MenuItem,
  SpotIcon,
  Typography,
  Icons,
  useConfirm,
} from '@passthrough/uikit';

import { useFundEdit } from 'services/providers/fund';
import { useMe } from 'services/providers/me';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import { EXPOSED_SIDE_LETTER_TYPES } from 'components/document_upload/constants';
import { CountersignButton } from './countersign_button';
import { SecondSignButton } from './second_sign_button';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(2, 3),
  },
  chipContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: theme.spacing(3),
  },
  signedSection: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(0.5),
  },
  chips: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(1),
    flexWrap: 'wrap',
  },
  docEleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(1),
  },
  docNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
  docNameAndTooltip: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  details: {
    display: 'flex',
    columnGap: theme.spacing(2),
    flex: 1,
  },
  listActions: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(1),
  },
}));

function getDocumentTypeDisplayName(type) {
  const documentType = EXPOSED_SIDE_LETTER_TYPES.find((t) => t.key === type);
  return documentType?.display || type;
}

export function DocumentSigningItem({
  lpDocId,
  lpClosingId,
  name,
  type,
  documentTypesToExclude,
  signers,
  mustCountersign,
  countersignOnClick,
  downloadOnClick,
  forceIcon,
  mustSecondSign, // LP view of the second signer
  isOffline,
  isPdfReady,
  isLoading,
  setLoading,
  disabled,
  setErrorMsg,
  alreadySigned,
  handleSign,
  canUpload,
  handleUpload,
  canRemoveDoc,
  canRemoveSignatures,
  sideletterId,
  offeringDocumentId,
  onChange,
  removeDocFromAlreadySigned,
  hasOnlineCountersigners,
}) {
  const classes = useStyles();
  const { fundId, closingId } = useParams();
  const [fundEdit] = useFundEdit();
  const [me] = useMe();
  const confirm = useConfirm();
  const { successToast } = useToast();
  const anchorRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  // This is used to move one of the signer chips to the signed section
  // when the current user signs. Can't just compare the signer userId
  // to the current user id because one user can sign for multiple signers.
  const [optimisticSignerName, setOptimisticSignerName] = useState('');

  useEffect(() => {
    if (alreadySigned && !optimisticSignerName) {
      for (let i = 0; i < signers.length; i += 1) {
        if (signers[i].userId === me.id && !signers[i].signed) {
          setOptimisticSignerName(signers[i].name);
          return;
        }
      }
    }
  }, [alreadySigned, optimisticSignerName]);

  // may be the case that a subdoc can be uploaded offline on the GP
  // side but does not require signatures at all
  const uploadBtnText =
    signers?.length > 0 ? 'Upload signed document' : 'Upload document';

  function openDoc() {
    const url = api.lpDocFinalPdfUri({ fundId, closingId, lpDocId });
    window.open(url, '_blank');
  }

  function clearSignatures() {
    api.clearLpDocSignatures({ fundId, lpDocId }).then(() => {
      setOptimisticSignerName('');
      removeDocFromAlreadySigned();
      onChange();
    });
  }

  function handleClearing() {
    confirm({
      title: 'Clear signatures?',
      description: (
        <>
          <Typography>
            All signatures will be cleared from this document.
          </Typography>
          <Typography>
            The investor will be asked to sign again when you request signature,
            or request changes to the questionnaire.
          </Typography>
        </>
      ),
      destructive: true,
      confirmationText: 'Clear signatures',
    })
      .then(clearSignatures)
      .catch(() => {});
  }

  function handleDelete() {
    confirm({
      description: `The document "${name}" will be removed. This action can’t be undone.`,
      destructive: true,
    })
      .then(() => {
        setLoading(true);

        api
          .detachDocument({
            fundId,
            closingId,
            lpClosingIds: [lpClosingId],
            sideletterId,
            offeringDocumentId,
          })
          .then(() => {
            successToast(`Removed ${name}`);
            onChange();
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});
  }

  const handleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  function DownloadButton({ tooltip }) {
    return (
      <Button
        variant="icon"
        onClick={downloadOnClick || openDoc}
        disabled={!isPdfReady}
        aria-label="View"
        tooltip={tooltip}
      >
        <Icons.OpenInNew fontSize="inherit" />
      </Button>
    );
  }

  function signerAlreadySigned(signer) {
    return signer.signed || signer.name === optimisticSignerName;
  }

  const usersSigned = (signers || []).filter((s) => signerAlreadySigned(s));
  const usersNotSigned = (signers || []).filter((s) => !signerAlreadySigned(s));

  const hasOnlineAndOffline = isOffline && hasOnlineCountersigners;

  const signedOffline = usersSigned.filter((s) => !s.isCountersigner);
  const signedOnline = usersSigned.filter((s) => s.isCountersigner);

  function Icon() {
    if (usersNotSigned.length > 0 || forceIcon === 'signature') {
      return (
        <SpotIcon size="medium">
          <SignatureFreehand sx={{ fontSize: '26' }} />
        </SpotIcon>
      );
    }
    if (usersSigned.length > 0 || forceIcon === 'check') {
      return (
        <SpotIcon size="medium" variant="success">
          <Icons.Check sx={{ fontSize: '26' }} />
        </SpotIcon>
      );
    }
    return (
      <SpotIcon size="medium" variant="neutral">
        <Icons.DescriptionOutlined sx={{ fontSize: '26' }} />
      </SpotIcon>
    );
  }

  function SignedUsers({ users, label }) {
    return (
      <>
        {users.length > 0 ? (
          <div className={classes.signedSection}>
            <Typography variant="label" color="text.secondary">
              {users.length} {label}
            </Typography>
            <div className={classes.chips}>
              {users.map((signer) => {
                const signedTime = parse(
                  signer.signed,
                  'MM/dd/yyyy',
                  new Date(),
                );
                let signedText = '';
                if (signer.name === optimisticSignerName) {
                  signedText = 'Signed just now';
                } else if (signer.signed) {
                  signedText = format(signedTime, 'd MMM yyyy');
                }

                return (
                  <Tooltip
                    key={signer.name}
                    title={
                      <Typography variant="label">{signedText}</Typography>
                    }
                  >
                    <Chip
                      variant="success"
                      label={signer.name}
                      icon={<Icons.Check fontSize="inherit" />}
                    />
                  </Tooltip>
                );
              })}
            </div>
          </div>
        ) : null}
      </>
    );
  }

  const disableRemoveDocument =
    isLoading || !canRemoveDoc || !(sideletterId || offeringDocumentId);

  const disableClearSignatures = isLoading || !canRemoveSignatures;

  return (
    <ListItem className={classes.listItem}>
      <div className={classes.docEleContainer}>
        <div className={classes.details}>
          <Icon />
          <div className={classes.docNameContainer}>
            <div>
              <div className={classes.docNameAndTooltip}>
                <Typography variant="card-heading">{name}</Typography>
                {documentTypesToExclude?.includes(type) ? (
                  <Tooltip
                    title={
                      <Typography variant="label">
                        Not included in closing emails
                      </Typography>
                    }
                  >
                    <EmailOffOutline color="action" fontSize="small" />
                  </Tooltip>
                ) : null}
              </div>
              <Typography variant="label" color="text.secondary">
                {getDocumentTypeDisplayName(type)}
              </Typography>
            </div>
            <div className={classes.chipContainer}>
              {hasOnlineAndOffline &&
              signedOffline.length > 0 &&
              signedOnline.length > 0 ? (
                <>
                  <SignedUsers users={signedOffline} label="Signed offline" />
                  <SignedUsers users={signedOnline} label="Signed online" />
                </>
              ) : (
                <SignedUsers
                  users={usersSigned}
                  label={`Signed${isOffline ? ' offline' : ''}`}
                />
              )}
              {usersNotSigned.length > 0 ? (
                <div className={classes.signedSection}>
                  <Typography variant="label" color="text.secondary">
                    {usersNotSigned.length} Not signed
                  </Typography>
                  <div className={classes.chips}>
                    {usersNotSigned.map((signer) => (
                      <Chip
                        key={signer.name}
                        variant="neutral"
                        label={signer.name}
                      />
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className={classes.listActions}>
          {mustCountersign && !alreadySigned ? (
            <CountersignButton
              lpDocId={lpDocId}
              lpClosingId={lpClosingId}
              isLoading={isLoading}
              setLoading={setLoading}
              handleSign={handleSign}
              disabled={disabled}
              setErrorMsg={setErrorMsg}
              onClick={countersignOnClick}
            />
          ) : null}

          {mustSecondSign && !alreadySigned ? (
            <SecondSignButton
              lpDocId={lpDocId}
              lpClosingId={lpClosingId}
              isLoading={isLoading}
              setLoading={setLoading}
              handleSign={handleSign}
              disabled={disabled}
              setErrorMsg={setErrorMsg}
            />
          ) : null}

          {!mustSecondSign && fundEdit && canUpload ? (
            <Button
              variant="secondary"
              startIcon={<Icons.FileUploadOutlined fontSize="inherit" />}
              onClick={handleUpload}
            >
              {uploadBtnText}
            </Button>
          ) : null}

          {/* This link only works on the GP side */}
          {!mustSecondSign && !isPdfReady ? (
            <DownloadButton tooltip="This document was just signed and the updated PDF is still being generated. This usually takes about one minute." />
          ) : null}
          {!mustSecondSign && isPdfReady ? <DownloadButton /> : null}
          {!disableClearSignatures || !disableRemoveDocument ? (
            <>
              <Button
                variant="icon"
                onClick={handleMenu}
                ref={anchorRef}
                aria-label="More actions"
              >
                <Icons.MoreVert fontSize="inherit" />
              </Button>
              <Menu
                id={`document-actions-menu-${lpDocId}`}
                anchorEl={anchorRef.current}
                open={menuOpen}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={handleClearing}
                  disabled={disableClearSignatures}
                  text="Clear signatures"
                  icon={<Icons.DeleteSweepOutlined color="error" />}
                />
                <MenuItem
                  onClick={handleDelete}
                  disabled={disableRemoveDocument}
                  text="Remove"
                  icon={<Icons.DeleteOutlined color="error" />}
                />
              </Menu>
            </>
          ) : null}
        </div>
      </div>
    </ListItem>
  );
}
