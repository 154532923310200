import React from 'react';
import { LPClosingStatusChip } from 'components/status';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@passthrough/uikit';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { TrigramMatchingText } from './TrigramMatchingText';
import { CollaboratorResult } from './CollaboratorResult';

const useStyles = makeStyles((theme) => ({
  listItemRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: theme.spacing(1, 2),
    // To prevent moving when adding border on hover
    border: `1px solid transparent`,
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.border}`,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.border}`,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.border}`,
    },
  },
  icon: {
    minWidth: '0',
    marginRight: theme.spacing(1),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '2px',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  secondaryRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  collaboratorRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  folderPathContainer: {
    display: 'flex',
    flexBasis: '33%',
    flexGrow: 8,
  },
  statusContainer: {
    display: 'flex',
    flexGrow: 3,
    justifyContent: 'flex-end',
  },
}));

export function InvestorSearchResult({
  lpClosing,
  onClick,
  searchString,
  isSelected,
}) {
  const classes = useStyles();

  const showLegalName =
    lpClosing.legalName && lpClosing.legalName !== lpClosing.lpName;

  const showCollaborators = lpClosing.collaborators?.length > 0;

  return (
    <ListItem
      button
      onClick={onClick}
      classes={{
        root: classes.listItemRoot,
      }}
      selected={isSelected}
    >
      <ListItemIcon className={classes.icon}>
        <DescriptionOutlinedIcon fontSize="small" />
      </ListItemIcon>
      <div className={classes.textContainer}>
        <div className={classes.headerRow}>
          <Typography component="p" size="medium">
            <TrigramMatchingText
              searchString={searchString}
              targetString={lpClosing.lpName}
              includeTypography={false}
            />{' '}
            {showLegalName ? (
              <>
                <span> • </span>
                <TrigramMatchingText
                  searchString={searchString}
                  targetString={lpClosing.legalName}
                  includeTypography={false}
                />
              </>
            ) : null}
          </Typography>
        </div>
        <div className={classes.secondaryRow}>
          <div className={classes.folderPathContainer}>
            <Typography variant="label" color="text.secondary" size="small">
              {lpClosing.fundName} / {lpClosing.closingName}
            </Typography>
          </div>
          <div className={classes.statusContainer}>
            <LPClosingStatusChip
              lpClosing={lpClosing}
              size="small"
              showTimeAgo={false}
            />
          </div>
        </div>
        {showCollaborators ? (
          <div className={classes.collaboratorRow}>
            {lpClosing.collaborators.map((c) => (
              <div>
                <CollaboratorResult
                  collaborator={c}
                  searchString={searchString}
                />
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </ListItem>
  );
}
